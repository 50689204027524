/* @flow */
import React from 'react'
import { Link } from 'react-router-dom'
import { userUrlByUsername } from 'profile/urls'
import { SuccessIcon } from 'common/components/icons'
import { keepQueryString } from 'urls'

class UserUnFollow extends React.PureComponent {
  render() {
    const { username } = this.props
    const userUrl = userUrlByUsername(username)

    return (
      <a href={keepQueryString(userUrl)}>
        <div className=" font-bold flex items-center gap-4">
          <SuccessIcon />

          <div className="flex items-center gap-1">
            <div>You unfollowed</div>
            <div className="link underline  !font-bold  ">
              <Link to={keepQueryString(userUrl)}>@{username}</Link>
            </div>
          </div>
        </div>
      </a>
    )
  }
}

export default UserUnFollow
