import React, { lazy } from 'react'
import {
  accountUrl,
  walletUrl,
  currencySelectorUrl,
  cashInUrl,
  cashInConfirmationUrl,
  withdrawUrl,
  addSkrillAccountUrl,
  withdrawValidationUrl,
  withdrawEmailReceivedUrl,
  personalInfoUrl,
  withdrawStatus,
  redeemCode,
  activityUrl,
} from 'urls'
import { settingsUrl } from 'profile/urls'
import { ViewPersonalInfo } from 'views/personal-Info-form'
import { ViewActivity } from 'views/activity'

const ViewRedeemCode = lazy(() => import('views/redeem-code').then(module => ({ default: module.ViewRedeemCode })))
const ViewPortfolio = lazy(() => import('views/portfolio').then(module => ({ default: module.ViewPortfolio })))
const ViewWallet = lazy(() => import('views/wallet').then(module => ({ default: module.ViewWallet })))
const ViewPublicProfile = lazy(() =>
  import('views/public-profile').then(module => ({ default: module.ViewPublicProfile }))
)
const ViewDeposit = lazy(() => import('views/deposit'))
const ViewWithdrawStatus = lazy(() => import('views/withdraw-status'))
const ViewWithdraw = lazy(() => import('views/withdraw'))
const ViewSkrillPayment = lazy(() => import('views/skrill-payment'))
const ViewCurrencySelector = lazy(() => import('views/currency-selector'))
const ViewCashInConfirmation = lazy(() => import('views/cash-in-confirmation'))
const ViewSettings = lazy(() => import('views/settings'))
const ViewUserList = lazy(() => import('views/user-list'))
const PublicFollow = lazy(() => import('profile/components/ViewPublicFollow'))
const ViewCryptoWithdrawValidation = lazy(() => import('views/withdraw-validation'))
const ViewWithdrawEmailReceived = lazy(() => import('views/withdraw-email-received')) 
export default [
  { path: walletUrl(), element: <ViewWallet /> },
  { path: addSkrillAccountUrl(), element: <ViewSkrillPayment />, isModal: true },
  { path: currencySelectorUrl(), element: <ViewCurrencySelector />, isModal: true },
  { path: withdrawUrl(), element: <ViewWithdraw />, isModal: true },
  { path: personalInfoUrl(), element: <ViewPersonalInfo />, isModal: true },
  { path: withdrawEmailReceivedUrl(), element: <ViewWithdrawEmailReceived />, isModal: true },
  { path: withdrawValidationUrl(), element: <ViewCryptoWithdrawValidation />, isModal: true },
  { path: cashInUrl(), element: <ViewDeposit />, isModal: true },
  { path: withdrawStatus(), element: <ViewWithdrawStatus />, isModal: true },
  { path: cashInConfirmationUrl(), element: <ViewCashInConfirmation />, isModal: true },
  { path: redeemCode(), element: <ViewRedeemCode />, isModal: true },
  { path: accountUrl(), element: <ViewPortfolio /> },
  { path: settingsUrl(), element: <ViewSettings key="view-settings" /> },
  { path: settingsUrl(':setting'), element: <ViewSettings key="view-settings" /> },
  { path: '/:username/followers', element: <ViewUserList isFollowingList={false} key="view-user-followers" /> },
  { path: '/:username/following', element: <ViewUserList isFollowingList={true} key="view-user-following" /> },
  { path: '/:username/follow', element: <PublicFollow /> },
  { path: '/:username', element: <ViewPublicProfile key="view-profile" /> },
  { path: activityUrl(), element: <ViewActivity /> },
]
