/* @flow */
import React from 'react'
import { SuccessIcon } from 'common/components/icons'
import { questionDetailUrl, keepQueryString } from 'urls'

import { i18n } from 'common/locale/i18n'
import { Link } from 'react-router-dom'
import { useCurrentRouteName } from 'common/hooks'

const WagerBuy = props => {
  const currentPage = useCurrentRouteName()
  const isMarketPage = currentPage === 'Market page'
  const { question } = props

  return (
    <a href={keepQueryString(questionDetailUrl(question))}>
      <div className=" font-bold flex items-center gap-4">
        <SuccessIcon />

        <div className="flex items-center gap-1">
          <div>{i18n('place_bet.bought')}</div>
          {!isMarketPage && (
            <div className="link underline  !font-bold  ">
              <Link to={keepQueryString(questionDetailUrl(question))}>{i18n('place_bet.view_market_details')}</Link>
            </div>
          )}
        </div>
      </div>
    </a>
  )
}

export default WagerBuy
