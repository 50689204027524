/* @flow */
import React from 'react'
import { walletUrl } from 'urls'
import { useNavigate } from 'react-router-dom'
import CurrencyIcon from 'common/components/currencyIcon'
class Deposit extends React.PureComponent {
  handleClick() {
    const navigate = useNavigate()
    const url = walletUrl()
    navigate(url)
  }

  render() {
    const { currency } = this.props
    return (
      <div className="flex items-center" onClick={this.handleClick.bind(this)}>
        {currency && <CurrencyIcon currency={currency} width="48px" />}

        <div className="flex items-center gap-1">
          <div>Deposit succeeded</div>
        </div>
      </div>
    )
  }
}

export default Deposit
