/* @flow */
import React from 'react'
import { walletUrl } from 'urls'
import { i18n } from 'common/locale/i18n'
import { SuccessIcon } from 'common/components/icons'

class Withdraw extends React.PureComponent {
  handleClick() {
    const { onMessageClick } = this.props
    if (onMessageClick) {
      const url = walletUrl()
      onMessageClick(url)
    }
  }

  render() {
    return (
      <div className="flex items-center" onClick={this.handleClick.bind(this)}>
        <SuccessIcon />

        <div className="flex items-center gap-1">
          <div>{i18n('withdraw.withdraw_done')}</div>
        </div>
      </div>
    )
  }
}

export default Withdraw
