import React from 'react'

const IconContainer = ({ children, size, onClick }) => {
  return (
    <div className="relative group flex justify-center items-center" onClick={onClick}>
      <div
        className="absolute rounded-full group-hover:bg-Neutral-2 z-0 cursor-pointer"
        style={{
          width: size * 2 + 12 + 'px',
          height: size * 2 + 12 + 'px',
          // left: -size,
          // top: -size,
        }}></div>
      <i className="z-20 relative ">{children}</i>
    </div>
  )
}

export default IconContainer
