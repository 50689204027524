/* eslint-disable no-unreachable */
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setAppTheme } from 'store/app'
import mixpanelService from 'app/analytics/Mixpanel'

function useTheme() {
  // /**
  //  * ? ------------------------
  //  * ! ----Remove dark mode----
  //  * * ------------------------
  //  */
  // localStorage.removeItem('theme')
  // return { theme: '', toggleTheme: () => {} }
  // /**
  //  * * ------------------------
  //  * ! ----Remove dark mode----
  //  * ? ------------------------
  //  */

  // Function to get user's preferred theme
  const getPreferredTheme = () => {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  }
  const [theme, setTheme] = useState(getPreferredTheme)
  const dispatch = useDispatch()

  const getTheme = () => {
    const storedTheme = localStorage.getItem('theme') || 'light'
    return storedTheme
  }

  // Initialize the theme state with the user's preferred theme or default to 'light'

  // Function to toggle the theme between 'light' and 'dark'
  const toggleTheme = () => {
    const newTheme = getTheme() === 'light' ? 'dark' : 'light' 
    document.documentElement.setAttribute('data-theme', newTheme)
    localStorage.setItem('theme', newTheme)
    dispatch(setAppTheme(newTheme))
    mixpanelService.addProperty('theme', newTheme)
  }
  const initTheme = () => {
    const storedTheme = localStorage.getItem('theme') || getPreferredTheme()
    setTheme(storedTheme)
    dispatch(setAppTheme(storedTheme))
    localStorage.setItem('theme', storedTheme)
    document.documentElement.setAttribute('data-theme', storedTheme)
  }

  // Return the theme value and the toggler function from the hook
  return { initTheme, theme, toggleTheme, getTheme }
}

export default useTheme
