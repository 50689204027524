import constants from 'app/constants'
import React from 'react'
import { selectExchangeRate, selectExchangeRateStatus } from 'store/app'
import { useSelector } from 'react-redux'
import { selectCurrencyProproties } from 'store/account'
import {
  BRLIcon,
  BinanceCoinBadge,
  BitcoinCashBadge,
  BtcCurrencyBadge,
  BtcIcon,
  BusdIcon,
  DogecoingBadge,
  EURIcon,
  EthereumBadge,
  LtcCurrencyBadge,
  MXNIcon,
  MoneroIcon,
  OomsIcon,
  RealMoneyIcon,
  SolanaIcon,
  TronBadge,
  USDIcon,
  UsdcBadge,
} from 'common/components/icons'
import { useState } from 'react'
import { useEffect } from 'react'

const useCurrencies = () => {
  const exchangeRate = useSelector(selectExchangeRate)
  const currencyProproties = useSelector(selectCurrencyProproties())
  const exchangeRateStatus = useSelector(selectExchangeRateStatus())

  const oomsCurrency = {
    id: 'OOM',
    label: 'Ooms',
    general_label: 'Play money',
    currencySymbol: <OomsIcon width="1em" />,
    display_ratio: 1,
    display_decimal_places: 2,
    display_suffix: 'ø',
    display_code: 'Ooms',
    defaultBet: 100,
    default_bet_amount: 100,
    mainColor: '#1591ed',
  }
  const [OOM, setOOM] = useState(oomsCurrency)
  const cryptoCurrencies = [...Object.keys(currencyProproties.currencies)].filter(
    key => !['OOM', 'USD', 'EUR'].includes(key)
  )
  const isCryptoCurrency = currencyId => {
    return cryptoCurrencies.indexOf(currencyId) >= 0
  }
  useEffect(() => {
    setOOM(oom => ({ ...oom, ...currencyProproties.currencies.OOM }))
  }, [currencyProproties.currencies])

  const getIcon = (currencyId, isDefault) => {
    const fill = isDefault && 'currentColor'
    switch (currencyId) {
      case 'BTC':
        return <BtcCurrencyBadge width="1.2em" fill={fill} />
      case 'LTC':
        return <LtcCurrencyBadge width="1.2em" fill={fill} />
      case 'LTCT':
        return <LtcCurrencyBadge width="1.2em" fill={fill} />
      case 'BCH':
        return <BitcoinCashBadge width="1.2em" fill={fill} />
      case 'BNB':
        return <BinanceCoinBadge width="1.2em" fill={fill} />
      case 'DOGE':
        return <DogecoingBadge width="1.2em" fill={fill} />
      case 'ETH':
        return <EthereumBadge width="1.2em" fill={fill} />
      case 'TRX':
        return <TronBadge width="1.2em" fill={fill} />
      case 'USDC':
        return <UsdcBadge width="1.2em" fill={fill} />
      case 'SOL':
        return <SolanaIcon width="1.2em" fill={fill} />
      case 'BUSD':
        return <BusdIcon width="1.2em" fill={fill} fillcolor="white" />
      case 'XMR':
        return <MoneroIcon width="1.2em" fill={fill} />
      case 'USD':
        return <USDIcon />
      case 'MXN':
        return <MXNIcon width="20px" />
      case 'EUR':
        return <EURIcon />
      case 'BRL':
        return <BRLIcon />

      default:
        break
    }
  }
  let realMoneyCurrencies = {
    ...Object.fromEntries(
      Object.entries(currencyProproties.currencies).filter(
        ([key]) => !['OOM'].includes(key) && currencyProproties.currencies[key]
      )
    ),
  }

  Object.keys(realMoneyCurrencies).forEach(_currency => {
    realMoneyCurrencies[_currency] = {
      ...realMoneyCurrencies[_currency],
      currencySymbol: getIcon(_currency),
      defaultCurrencySymbol: getIcon(_currency, true),
      id: _currency,
    }
  })

  const realMoneyPlaceholder = {
    id: constants.REAL_MONEY_PLACEHOLDER,
    label: 'Real Money',
    symbol: <RealMoneyIcon width="1em" fill="currentColor" />,
    currencySymbol: <RealMoneyIcon />,
    display_code: 'Real Money',
    display_ratio: 1,
    display_decimal_places: 2,
    default_bet_amount: 5,
  }
  const testCoin = {
    id: 'TEST',
    label: 'TestCoin',
    symbol: <span style={{ fontFamily: 'Encode Sans' }}>Test</span>,
    currencySymbol: <span>Test</span>,
    display_code: 'TC',
    display_ratio: 0.001,
    display_decimal_places: 8,
    icon: BtcIcon,
    mainColor: '#F7931A',
    defaultBet: 0.0002,
    default_bet_amount: 0.0002,
  }

  const parseCurrencyDecimalPlaces = (decimalPlaces, ratio) => {
    return ratio === 1 ? decimalPlaces : decimalPlaces - Math.abs(Math.log10(ratio))
  }
  const isPlaceHolder = currencyId => currencyId === constants.REAL_MONEY_PLACEHOLDER
  const isRealMoney = currencyId => currencyId && currencyId !== oomsCurrency.id && !isPlaceHolder(currencyId)

  const getCurrencyParameter = isRealMoney => (isRealMoney ? 'BTC' : oomsCurrency.id)
  const parseCurrencyParameter = currencyId => getCurrencyParameter(isRealMoney(currencyId))
  const getMarketType = isRealMoney => (isRealMoney ? constants.REAL_MONEY : constants.PLAY_MONEY)
  const getCurrencyMode = currencyId => getMarketType(isRealMoney(currencyId))
  const convertBitcoinTo = (valueInBtc, exchangeRate) => {
    return valueInBtc * exchangeRate
  }

  const convertToBitcoin = (valueInCurrency, exchangeRate) => {
    return valueInCurrency / exchangeRate
  }

  return {
    oomsCurrency,
    testCoin,
    blockchains: currencyProproties.blockchains,
    realMoneyCurrencies,
    status: exchangeRateStatus,
    currencies: {
      ...realMoneyCurrencies,
      OOM: { ...OOM },
      [constants.REAL_MONEY_PLACEHOLDER]: realMoneyPlaceholder,
    },
    aggregatedCurrencies: {
      ...realMoneyCurrencies,
      OOM: { ...OOM },
      [constants.REAL_MONEY_PLACEHOLDER]: realMoneyPlaceholder,
    },
    CANONICAL: { ...currencyProproties.currencies['BTC'] },
    [constants.REAL_MONEY_PLACEHOLDER]: realMoneyPlaceholder,
    OOM: { ...OOM },
    parseCurrencyDecimalPlaces: parseCurrencyDecimalPlaces,
    isRealMoney: isRealMoney,
    parseCurrencyParameter: parseCurrencyParameter,
    getCurrencyMode: getCurrencyMode,
    getMarketType: getMarketType,
    convertToBitcoin: convertToBitcoin,
    convertBitcoinTo: convertBitcoinTo,
    exchangeRate: exchangeRate,
    isCryptoCurrency: isCryptoCurrency,
  }
}

export default useCurrencies
