import { useEffect } from 'react'
import '@n8n/chat/style.css'
// import { createChat } from '@n8n/chat'

const useChatBot = () => {
  useEffect(() => {
    // createChat({
    //   webhookUrl: 'http://127.0.0.1:5678/webhook/cffe9398-513e-49ad-bebb-ee708402b391/chat',
    //   initialMessages: ['Hi there forecaster! 👋', 'My name is Lucas. How can I assist you today?'],
    // })
  }, [])
}

export default useChatBot
