import { selectActiveLanguage } from 'store/app'

class SettingsService {
  constructor() {
    this.store = null;
  }

  setStore(storeInstance) {
    this.store = storeInstance;
  }

  getLanguage() {
    if (!this.store) return null;
    const state = this.store.getState();
    return selectActiveLanguage()(state);
  }
}

const instance = new SettingsService();
export default instance;
