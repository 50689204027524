import React from 'react'

import './SignupLayout.scss'
const SignupLayout = ({ children, title, style }) => {
  return (
    <section>
      <div className=" " style={style}>
        <main className=" ">
          {title && <h2 className="signup-layout__title">{title}</h2>}
          {children}
        </main>
      </div>
    </section>
  )
}

export default SignupLayout
