/**
 * Strips HTML tags from a given string.
 *
 * @param {string} htmlString - The string containing HTML tags.
 * @returns {string} - The plain text without HTML tags.
 */
export function stripHtmlTags(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc.body.textContent || "";
}


/**
 * Removes all spaces from a given string.
 *
 * @param {string} str - The input string.
 * @returns {string} - The string without any spaces.
 */
export function removeSpaces(str) {
  return str.replace(/\s+/g, '');
}

/**
 * Removes all line breaks from a given string.
 *
 * @param {string} str - The input string.
 * @returns {string} - The string without any line breaks.
 */
export function removeLineBreaks(str) {
  return str.replace("\\n", '');
}

export function isOnStaging()
{
  let app_front_url = process.env.REACT_APP_FRONT_URL

  let app_front_split = app_front_url.split('.')

  return app_front_split.filter((str) => str === 'https://staging').length > 0
}
