import React from 'react'
import { useNavigate } from 'react-router-dom'
import { signupFormUrl, keepQueryString } from 'urls'
import { EmailIcon } from 'common/components/icons'
import Button from 'common/ui/button'
import './EmailLogin.scss'

const EmailLogin = ({ label, iconOnly, color }) => {
  const navigate = useNavigate()
  return (
    <Button
      className="email-login__button"
      type="outlined"
      variant="shade"
      size="full"
      onClick={() => navigate(keepQueryString(signupFormUrl()))}>
      <EmailIcon fillcolor={color || 'currentColor'} className="email-login__icon" width="16px" />
      {!iconOnly && label}
    </Button>
  )
}

export default EmailLogin
