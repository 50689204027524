/* @flow */
import React from 'react'
import { Link } from 'react-router-dom'
import Sentry from 'app/sentry/Sentry'
import analyticsService from 'common/services/analytics'
import { feedUrl, feedbackUrl } from 'urls'
import { i18n } from 'common/locale/i18n'
import './ErrorHandler.scss'
import Fishbowl from 'common/components/fish-bowl'

class ErrorHandler extends React.Component {
  state = {
    hasError: false,
    error: null,
    displayFullError: false,
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }
 
  renderNotificationError() {
    return (
      <div className="feedError">
        <div className="feedError__container">
          <div className="feedError__tooltip">
            <div className="feedError__tooltip__image"></div>
            <i className="tooltip__arrow"></i>
          </div>
          <p
            className="view-how-to-buy-bitcoin__intro"
            dangerouslySetInnerHTML={{ __html: i18n('error_messages.feed_page_crash') }}
          />
        </div>
      </div>
    )
  }
  componentDidCatch(error, errorInfo) {
    const { location } = this.props
    analyticsService.trackError({
      currentUrl: location.pathname,
      error: JSON.stringify(error),
    })
    Sentry.log(error)
    this.setState({ error: '<b>' + error + '</b><br/><br/> ' + errorInfo.componentStack })
  }

  restart() {
    this.setState({ hasError: false })
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children
    } else if (this.props.component === 'notification-widget') {
      return this.renderNotificationError()
    }
    return (
      <div
        className={
          'error-handler ' + (this.state.error && this.state.displayFullError ? ' error-handler__bigger' : '')
        }>
        <div className="error-handler__message">
          <Fishbowl />
          <span>Oops</span>

          <p dangerouslySetInnerHTML={{ __html: i18n('error.text') }} />
          <Link className="button button--error" to={feedUrl()} onClick={this.restart.bind(this)}>
            {i18n('error.button')}
          </Link>
          <div className="error-handler__more-option">
            <Link to={feedbackUrl('bug-report')}>report this bug</Link> &nbsp; or &nbsp;
            <span onClick={() => this.setState({ displayFullError: true })} className="link">
              See full error
            </span>
          </div>
          {this.state.error && this.state.displayFullError && (
            <div className="error-handler__full_error" dangerouslySetInnerHTML={{ __html: this.state.error }}></div>
          )}
        </div>
      </div>
    )
  }
}

export default ErrorHandler
