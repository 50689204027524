import React from 'react'
import Toast from 'app/components/toast'
import { toast } from 'react-toastify'
import { CrossIcon } from 'common/components/icons'
// import Device from 'common/util/Device'

export const toastService = (type, data, customOptions) => {
  const options = {
    hideProgressBar: true,
    autoClose: 3000,
    closeOnClick: true,
    position: 'top-right',
    pauseOnHover: true,
    draggable: true,
    newestOnTop: true,
    progress: undefined,
    closeButton: (
      <i className="w-10 h-10 flex justify-content-center align-center">
        <CrossIcon width="14px" />
      </i>
    ),
    icon: false,
    theme: localStorage.getItem('theme') || 'light',
    ...customOptions, 
  }

  const toastContent = <Toast component={type} _data={data} className={type} />

  toast(toastContent, options)
}
