import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { fetchMarketById, selectMarketById, selectMarketErrorById, selectMarketStatusById } from 'store/markets'
import { howItWorksUrl } from 'landing/urls'
import { i18n } from 'common/locale/i18n'
import { isBetSelectable } from 'common/lib/bets'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { keepQueryString, notFoundUrl } from 'urls'
import { useActiveCurrency, useCurrentUser, useScrollDirection } from 'common/hooks'
import { useDispatch, useSelector } from 'react-redux'
import constants from 'app/constants'
import LoadingIndicator from 'common/components/LoadingIndicator'
import Market from 'questions/components/market'
import './ViewMarketPage.scss'
import { selectActiveLanguage, selectActiveMarketType, selectPageLayout } from 'store/app'
import { useAutoAnimate } from '@formkit/auto-animate/react'
// import html2canvas from 'html2canvas'
import HelmetMarketImage from 'questions/components/helmet-market-image'
import { fetchQuestionBetHistory, resetBetHistory } from 'store/wagers'
import useIsMobileSize from 'common/hooks/useIsMobileSize'
import PlaceBetSkeleton from 'questions/components/place-bet/PlaceBetSkeleton'

const BannerIntroMarketDetail = lazy(() => import('questions/components/banner-intro-market-detail'))
const PlaceBet = lazy(() => import('questions/components/place-bet'))

const ViewMarketPage = props => {
  const { questionId } = useParams()
  const { className, style } = props
  const dispatch = useDispatch()
  const marketType = useSelector(selectActiveMarketType)
  const pageLayout = useSelector(selectPageLayout)
  const navigate = useNavigate()
  const user = useCurrentUser()
  const viewRef = useRef()
  const locale = useSelector(selectActiveLanguage())
  const activeCurrency = useActiveCurrency()
  const question = useSelector(selectMarketById(questionId))
  const [position, setPosition] = useState(null)

  const questionError = useSelector(selectMarketErrorById(questionId))
  const questionStatus = useSelector(selectMarketStatusById(questionId))
  const isLoading =
    questionStatus === constants.LOADING || (question && isBetSelectable(question.wager) && !question.complete)
  const [selectedOutcome, setSelectedOutcome] = useState(location.state && location.state.outcome)
  const [placebetOpen, setPlacebetOpen] = useState(false)
  const scrollDirection = useScrollDirection(0)
  const [isVisible, setVisible] = useState(true)
  const [animationParent] = useAutoAnimate()
  const isMobileSize = useIsMobileSize()

  useEffect(() => {
    setVisible(scrollDirection !== 'down')
  }, [scrollDirection])
  useEffect(() => {
    if ((questionStatus !== constants.COMPLETE || !question.complete) && !pageLayout.appLoading) {
      dispatch(fetchMarketById(questionId, marketType))
    }
  }, [props.location, pageLayout.appLoading, activeCurrency.id, questionId])
  useEffect(() => {
    if (questionStatus === constants.COMPLETE && !pageLayout.appLoading) {
      dispatch(fetchMarketById(questionId, marketType))
    }
  }, [marketType, pageLayout.appLoading])
  useEffect(() => {
    if (questionStatus === constants.ERROR && questionError.status === 404) {
      navigate(notFoundUrl(), { replace: true })
    }
  }, [questionStatus])
  useEffect(() => {
    setTimeout(() => {
      if (questionStatus === constants.COMPLETE) window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 200)
  }, [questionId, questionStatus])
  useEffect(() => {
    if (selectedOutcome) {
      setPlacebetOpen(true)
    }
  }, [selectedOutcome])

  // Auto-select the first outcome (Yes) for yes/no questions if no outcome is selected
  useEffect(() => {
    if (
      !isMobileSize &&
      question &&
      question.outcomes_type === 'yesno' &&
      !selectedOutcome &&
      question.outcomes &&
      question.outcomes.length >= 2 &&
      questionStatus === constants.COMPLETE
    ) {
      setSelectedOutcome(question.outcomes[0])
    }
  }, [question, questionStatus, isMobileSize])

  // const renderBreadcrumb = () => {
  //   const doc = {
  //     '@context': 'https://schema.org',
  //     '@type': 'BreadcrumbList',
  //     itemListElement: [],
  //   }

  //   question.category.forEach((category, index) => {
  //     const baseUrl = process.env.REACT_APP_SHARE_PATH || process.env.REACT_APP_FRONT_URL.slice(0, -1) || ''
  //     const url = new URL(categoryListUrl(category), baseUrl).href
  //     doc.itemListElement.push({
  //       '@type': 'ListItem',
  //       position: index + 1,
  //       name: category.title,
  //       item: url,
  //     })
  //   })

  //   return <script type="application/ld+json">{JSON.stringify(doc)}</script>
  // }

  const renderSidebar = () => {
    const englishURL = 'https://www.youtube.com/watch?v=qKaozE7ZS78'
    const portugueseURL = 'https://www.youtube.com/watch?v=-Q54jIsnCNo'
    return (
      <div className="question-details__sidebar">
        <Suspense fallback={<LoadingIndicator mode="inline" />}>
          <BannerIntroMarketDetail text={i18n('feed_banner.Trade_the_future')} />
        </Suspense>
        <div className="question-details__sidebar__line" />
        <ul className="question-details__sidebar__links">
          <li>
            <a href={locale.indexOf('en') >= 0 ? englishURL : portugueseURL} target="_blank" rel="noreferrer">
              {i18n('question_details.sidebar_video')}
            </a>
          </li>
          <li>
            <Link to={keepQueryString(howItWorksUrl())}>{i18n('question_details.sidebar_hiw')}</Link>
          </li>
        </ul>
      </div>
    )
  }

  const renderLoading = () => {
    return (
      <div className="question-details--loading">
        <LoadingIndicator mode="list" />
      </div>
    )
  }

  const renderMakeBet = () => {
    const classList = classNames('question-card__section', 'question-card__make-bet', 'question-card__section--wide', {
      'question-card__section--loading': isLoading,
    })
    const updatedOutcome =
      selectedOutcome && question.outcomes && question.outcomes.find(o => o.id === selectedOutcome.id)

    return (
      <div className={classList}>
        {question && question.scoring_rule_metadata && (
          <Suspense fallback={<PlaceBetSkeleton />}>
            <PlaceBet
              question={question}
              open={placebetOpen}
              outcome={updatedOutcome}
              onChangeOutcome={setSelectedOutcome}
              onCancel={() => setSelectedOutcome(null)}
              setPlacebetOpen={setPlacebetOpen}
              onToggle={() => setPlacebetOpen(v => !v)}
              position={position}
              onWagerAction={() => {
                dispatch(resetBetHistory({ questionId: question.id }))
                dispatch(fetchQuestionBetHistory(question, {}, true))
              }}
              place="Market page"
            />
          </Suspense>
        )}
      </div>
    )
  }

  const render = () => {
    if (!question || pageLayout.appLoading) {
      return renderLoading()
    }

    return (
      <React.Fragment>
        <div className={classNames('question-details', className)} style={style} ref={viewRef}>
          {/* {placebetOpen && isMobileSize && <div className="place-bet__mobile_overlay"></div>} */}
          <div className="question-details__question-card" ref={animationParent}>
            <Market
              className="question-card__maindetails"
              bet={question.wager}
              categories={question.category}
              inlineBehavior
              question={question}
              showChart
              showLatestBets
              showMakeBet={isBetSelectable(question.wager)}
              showSelectedOutcome
              showSharePanel
              showSummary
              showComments
              showRelatedMarket
              showBetStatus
              loadingType="page"
              currentOutcomeSelection={selectedOutcome}
              onOutcomeSelected={(outcome, position) => {
                setSelectedOutcome(outcome)
                if (position === 'yes' || position === 'no') {
                  setPosition(position)
                }
              }}
              inMarketPage
            />
          </div>
          <aside
            className={classNames('question-details__aside', {
              'question-details__aside--closed': !placebetOpen && isMobileSize,
              'question-details__aside--closed--tabBarUp': !placebetOpen && isVisible && isMobileSize,
            })}>
            {renderMakeBet()}
            {(!user || !user.id) && renderSidebar()}
          </aside>
        </div>
        <HelmetMarketImage market={question} />
      </React.Fragment>
    )
  }

  return render()
}

export default ViewMarketPage
