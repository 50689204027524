import React from 'react'
import classnames from 'classnames'
import LoadingIndicator from 'common/components/LoadingIndicator' 
import './Button.scss'


/**
 * @typedef {'small' | 'medium' | 'large' | 'full'} ButtonSize
 * @typedef {'text-only' | 'outlined' | 'solid' | 'discreet'} ButtonType
 * @typedef {'primary' | 'shade' | 'white'} ButtonVariant
 */

/**
 * @param {Object} props
 * @param {React.ReactNode} [props.children]
 * @param {string} [props.className]
 * @param {Function} [props.onClick]
 * @param {ButtonSize} [props.size]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.loading]
 * @param {ButtonVariant} [props.variant]
 * @param {ButtonType} [props.type]
 * @param {'button' | 'submit' | 'reset'} [props.buttonType]
 */
function Button({
  children,
  className,
  onClick,
  size = 'small',
  disabled = false,
  loading = false,
  variant = 'primary',
  type = 'solid',
  buttonType,
}) {
  const classList = classnames(
    'button-main',
    `button-main--size-${size}`,
    `button-main--type-${type}`,
    `button-main--variant-${variant}`,
    {
      'button-main--loading': loading,
    },
    className
  );

  const onClickWrap = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      role="button"
      type={buttonType || 'submit'}
      className={classList}
      onClick={onClickWrap}
      onMouseUp={(e) => e.target.blur()}
      onMouseLeave={(e) => e.target.blur()}
      disabled={disabled}
    >
      {loading ? (
        <LoadingIndicator mode="inline" style={{ fill: 'currentColor' }} />
      ) : (
        children
      )}
    </button>
  );
}

export default Button;
