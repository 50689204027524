import React from 'react'
import { questionDetailUrl } from 'urls'
import Helmet from 'common/components/helmet'
import ShareService from 'social/services/share'
import futuurCover from 'landing/assets/futuur.com.png'
import { i18n } from 'common/locale/i18n'
import './HelmetMarketImage.scss'

const getPlatform = () => {
  return location.host === 'futuur.com' ? 'production' : 'development'
}

const S3_BUCKET_URL =
  getPlatform() === 'production'
    ? 'https://futuur-media-production.s3.amazonaws.com'
    : 'https://futuur-staging-media-nonprod.s3.amazonaws.com'
const SCREENSHOTS_PATH = 'question_screenshots'

const getMarketImageUrl = id => `${S3_BUCKET_URL}/${SCREENSHOTS_PATH}/${id}.png`

const extractMarketInfoFromUrl = () => {
  const path = window.location.pathname
  const matches = path.match(/\/q\/(\d+)\/(.+)/)
  if (matches) {
    const [, id, slug] = matches
    const title = slug.split('-').join(' ')
    return { id, title, slug }
  }
  return null
}

const HelmetMarketImage = ({ market }) => {
  const renderHelmet = question => {
    if (!question) {
      const urlInfo = extractMarketInfoFromUrl()
      if (urlInfo) {
        const title = `${urlInfo.title} ${i18n('question_details.meta_tags.title_suffix')}`
        const url = window.location.href
        const images = getMarketImageUrl(urlInfo.id)
        const description = i18n('question_details.meta_tags.description', {
          subcategory: 'Market',
        })

        return <Helmet id="view-question-details" url={url} title={title} images={images} description={description} />
      }
      return null
    }

    const categories = question.category.map(category => category.title)
    const title = `${question.title} - ${categories[categories.length - 1]} ${i18n(
      'question_details.meta_tags.title_suffix'
    )}`
    const url = ShareService.shareableUrl(questionDetailUrl(question))
    const images = question.id ? getMarketImageUrl(question.id) : futuurCover

    const description = i18n('question_details.meta_tags.description', {
      subcategory: categories[categories.length - 1],
    })

    return <Helmet id="view-question-details" url={url} title={title} images={images} description={description} />
  }

  return renderHelmet(market)
}

export default HelmetMarketImage
