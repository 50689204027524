import React from 'react'
import PersonIcon from 'common/components/icons/person'
import { useActiveCurrency, useCurrencies, useCurrentUser } from 'common/hooks'
import Tooltip from 'common/components/tooltip'
import { EyeIcon, OomsIcon, RealMoneyIcon, StatsIcon, Crevron, ShareIcon } from 'common/components/icons'
import { formatNumber } from 'common/util/localizeNumber'
import { selectActiveLanguage, selectPageLayout } from 'store/app'
import { useDispatch, useSelector } from 'react-redux'
import { i18n } from 'common/locale/i18n'
import classNames from 'classnames'
import { useModal } from 'app/components/modal-provider/ModalProvider'
import EmailNotConfirmed from 'app/components/modal-provider/global-modal/email-not-confirmed'
import { followOrUnfollow } from 'store/markets'
import { toastService } from 'api/toast'
import ExternalIcon from 'common/components/icons/ExternalIcon'
import { adminPanelEditMarketUrl, questionDetailUrlById } from 'urls'
import OverlayPanel from 'common/components/OverlayPanel'
import ShareService from 'social/services/share'
import Device from 'common/util/Device'
import useIsMobileSize from 'common/hooks/useIsMobileSize'

const QuestionFooter = ({
  question,
  inMarketPage,
  hasBets = false,
  isBetListCollapsed = false,
  toggleBetListCollapse = () => {},
  onCollapseButtonHover = () => {},
}) => {
  const locale = useSelector(selectActiveLanguage())
  const currencies = useCurrencies().currencies
  const activeCurrency = useActiveCurrency()
  const { adminMode } = useSelector(selectPageLayout)
  const currentUser = useCurrentUser()
  const { showModal } = useModal()
  const isMobileSize = useIsMobileSize()
  const dispatch = useDispatch()
  const isRealMoney = activeCurrency && activeCurrency.id !== currencies.OOM.id

  const volumeLabel = question => {
    if (!question || (isRealMoney && !question.volume_real_money) || (!isRealMoney && !question.volume_play_money))
      return null
    let label = isRealMoney
      ? '$' + formatNumber(question.volume_real_money, locale, 2, 2)
      : formatNumber(question.volume_play_money, locale, 0, 0)

    return <span>{label}</span>
  }
  const renderVolume = question => {
    return volumeLabel(question) ? (
      <div>
        <div className="question-card__volume-text">
          <StatsIcon fillcolor={'var(--color-Neutral-4)'} width="13px" />
          <span>
            {volumeLabel(question)}
            {currencies[activeCurrency.id].id === 'OOM' ? ' ø' : ''}
          </span>
        </div>
      </div>
    ) : (
      <></>
    )
  }

  const renderNumberOfBets = question => {
    return (
      <div>
        <div className="question-card__number-of-bets-text">
          <PersonIcon className="question-card__person-icon" fillcolor="currentColor" width="13px" />

          <span className="question-card__wager-count">{forecastersCount(question)}</span>
        </div>
      </div>
    )
  }
  const renderRelevance = question => {
    return (
      <div className="w-full">
        <div className="question-card__number-of-bets-text">
          <div className="flex flex-row justify-content-between items-center w-full">
            <div className="question-card__wager-count flex flex-row items-center">
              {i18n('markets.relevance') + ': '}
              {question.relevance != null ? question.relevance : '-'}

              {!!question.hot && <span title="This market is set as hot">🔥</span>}
            </div>
            <a
              href={adminPanelEditMarketUrl(question.id)}
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer"
              title="Edit market in admin panel">
              <ExternalIcon fillColor="var(--color-Neutral-6)" width="20px" height="20px" />
            </a>
          </div>
        </div>
      </div>
    )
  }

  const forecastersCount = question => {
    return question.wagers_count_total || question.wagers_count_canonical
  }

  const renderStaffModeData = question => {
    return (
      <div className="rounded-lg bg-[var(--color-surface-1)] text-Neutral-6 py-3 px-2 mt-6">
        <ul className="flex flex-col gap-4">
          <li className="d-flex items-center w-full">{currentUser && adminMode && renderRelevance(question)}</li>
        </ul>
      </div>
    )
  }

  const betSummary = renderNumberOfBets(question)
  const volume = renderVolume(question)
  // const currencyInfos = renderCurrencyInfos(activeCurrency.id)

  const renderLeftSideTooltip = () => {
    if (question.wagers_count_total > 0 || question.volume_play_money + question.volume_real_money > 0)
      return (
        <Tooltip
          light
          indicatorClassName="hidden"
          componentClassName="question-card__general-info-left--first__tooltip">
          <div className="flex gap-4 justify-between w-full">
            <div className="flex flex-col gap-2">
              <div className="flex gap-1 text-sm font-bold">
                <OomsIcon width="20px" /> <span>{i18n('Play_money')} </span>
              </div>
              <div className="flex gap-1">
                <PersonIcon fillcolor="currentColor" width="13px" />
                <span>{question.wagers_count} </span>
              </div>

              <div className="flex gap-1">
                <StatsIcon fillcolor={'var(--color-Neutral-5)'} width="13px" />
                <span>{formatNumber(question.volume_play_money, locale, 0, 0)}ø</span>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-1  text-sm font-bold">
                <RealMoneyIcon width="20px" /> <span>{i18n('Real_money')} </span>
              </div>
              <div className="flex gap-1">
                <PersonIcon fillcolor="currentColor" width="13px" />
                <span>{question.wagers_count_canonical}</span>
              </div>
              <div className="flex gap-1">
                <StatsIcon fillcolor={'var(--color-Neutral-5)'} width="13px" />
                <span>≈${formatNumber(question.volume_real_money, locale, 2, 2)}</span>
              </div>
            </div>
          </div>
        </Tooltip>
      )
  }
  const toogleFollow = async () => {
    if (currentUser && !currentUser.email_confirmed) {
      showModal({
        title: ' ',
        showCloseButton: true,
        content: <EmailNotConfirmed />,
      })
      return
    }
    await dispatch(followOrUnfollow(question.id))
    // await dispatch(fetchMarketById(question.id))
    toastService('marketFollow', {
      marketTitle: question.title,
      picture: question.outcomes[0].image_2x,
      is_followed: question.is_following,
    })
  }
  const renderShareFollow = () => {
    return (
      <div className="flex justify-between items-center ">
        <div className="flex gap-4 items-center">
          <div
            className="relative flex"
            onClick={() => {
              if (currentUser) toogleFollow(question.is_following)
            }}>
            <OverlayPanel
              isOverlayEnabled={!currentUser}
              customPosition={{ left: '-120px', bottom: 20 }}
              className="w-fit whitespace-nowrap !p-4"
              hideDelay={400}
              content="Login to be able to follow this market">
              <i
                className={classNames('cursor-pointer', {
                  ' opacity-20': !currentUser,
                })}>
                {renderFollowMarket()}
              </i>
            </OverlayPanel>
          </div>

          <div>{renderShareDropDown()}</div>
        </div>
      </div>
    )
  }

  const renderShareDropDown = () => {
    const handleShareClick = () => {
      const shareUrl = ShareService.shareableUrl(window.location.pathname)

      const copyLink = async () => {
        if (Device.isMobile) {
          await Clipboard.write({
            string: shareUrl,
          })
        } else {
          navigator.clipboard.writeText(shareUrl)
        }
        toastService('success', { title: i18n('snackbar.copylink'), body: i18n('markets.link_copied') })
      }

      if (isMobileSize) {
        if (navigator.share) {
          navigator
            .share({
              title: question.title,
              url: questionDetailUrlById(question.id),
            })
            .then(() => console.log('Successful share'))
            .catch(error => {
              console.log('Error sharing:', error)
              copyLink()
            })
        }
      } else {
        copyLink()
      }
    }
    return (
      <div className="question-header__share-dropdown">
        <div className="cursor-pointer" onClick={handleShareClick}>
          <ShareIcon width="16px" />
        </div>
        {/* <div
          onMouseOver={activatePanel}
          onMouseLeave={deactivatePanel}
          className={classNames('question-header__share-dropdown--panel', {
            'question-header__share-dropdown--panel--active': sharePanelActive,
          })}>
          <h3>Share on:</h3>
          <SharePanel />
        </div> */}
      </div>
    )
  }

  const renderFollowMarket = () => {
    return <EyeIcon width="16px" outline={!question.is_following} />
  }

  const renderCollapseButton = () => {
    if (!hasBets || inMarketPage) return null

    const betCount = question.wagers.length || 0

    return (
      <div
        className="cursor-pointer flex gap-1 items-center px-1 py-2 rounded-full text-sm font-medium transition-all bg-Neutral-2 text-Neutral-4 hover:bg-Neutral-3"
        onClick={toggleBetListCollapse}
        onMouseEnter={onCollapseButtonHover}
        title={isBetListCollapsed ? i18n('question_card.show_bets') : i18n('question_card.hide_bets')}>
        <span className=" inline-flex items-center justify-center min-w-[20px] h-5 px-1 bg-[var(--color-Primary-1)] text-[var(--color-Primary-5)] rounded-full text-xs font-semibold">
          {formatNumber(betCount, locale, 0, 0)}
        </span>
        <span>{isBetListCollapsed ? i18n('question_card.bets') : i18n('question_card.bets')}</span>
        <span
          className={`mr-2 inline-flex items-center justify-center w-5 h-5 transition-transform duration-200 ${
            isBetListCollapsed ? '' : 'transform rotate-180'
          }`}>
          <Crevron width="12px" height="12px" fillcolor="var(--color-Neutral-6)" />
        </span>
      </div>
    )
  }

  return (
    <>
      <div className="question-card__general-info">
        <div className="question-card__number-of-bets">
          {renderLeftSideTooltip()}
          <div
            className={classNames(
              'flex items-center gap-4 question-card__number-of-bets question-card__number-of-bets--labels',
              {
                'md:w-full md:justify-between': inMarketPage,
              }
            )}>
            {betSummary}
            {volume}
          </div>
        </div>

        <div className="flex items-center gap-3">
          {inMarketPage && renderShareFollow()}
          {renderCollapseButton()}
        </div>
      </div>

      {currentUser && adminMode && renderStaffModeData(question)}
    </>
  )
}

export default QuestionFooter
