import React from 'react'
import Button from 'common/ui/button'
import { FacebookLogin } from '@capacitor-community/facebook-login'
import { useDispatch } from 'react-redux'
import { loginFacebook } from 'store/account'

const FacebookLoginComponent = props => {
  const { label } = props
  const dispatch = useDispatch()

  const onLogin = async () => {
    const { onError, onSuccess } = props
    try {
      const FACEBOOK_PERMISSIONS = ['email', 'public_profile']
      const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })

      if (result && result.accessToken) {
        await dispatch(loginFacebook(result.accessToken.token, {}))
        if (onSuccess) {
          onSuccess()
        }
      }
    } catch (error) {
      console.error('[FB] Login error:', error)
      if (onError) {
        onError(error)
      }
    }
  }

  return (
    <Button className="gap-2 !bg-[#1877F2] hover:!bg-[#004298] px-3" type="solid"  size="full" onClick={onLogin}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 50 50" fill='white'>
        <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"></path>
      </svg>
      <span className="text-white">{!props.iconOnly && label}</span>
    </Button>
  )
}

export default FacebookLoginComponent
