import requestService from 'common/services/request'
import { createSlice } from '@reduxjs/toolkit'
import { transformArrayIntoMap } from 'common/util/mapArray'
import { getMarketType } from 'common/util/currencies'
import constants from 'app/constants'

const initialState = {
  pool: { data: {}, status: {}, error: {} },
  tree: {
    [constants.PLAY_MONEY]: { status: {}, data: {}, error: {} },
    [constants.REAL_MONEY]: { status: {}, data: {}, error: {} },
    ALL: { status: constants.IDLE, data: {}, error: {} },
  },
  root: {
    [constants.PLAY_MONEY]: { status: constants.IDLE, data: [], error: {} },
    [constants.REAL_MONEY]: { status: constants.IDLE, data: [], error: {} },
    ALL: { status: constants.IDLE, data: [], error: {} },
  },
  featured: {
    status: constants.IDLE,
    data: [],
    error: {},
  },
}

// Reducers / Slices
const categories = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    getRootCategoriesStart: (state, action) => {
      const { marketType } = action.payload
      state.root[marketType].status = constants.LOADING
    },

    getRootCategoriesSuccess: (state, action) => {
      const { marketType, categories } = action.payload
      const categoriesObj = transformArrayIntoMap(categories)
      state.pool.data = { ...state.pool.data, ...categoriesObj }
      state.root[marketType].status = constants.COMPLETE
      state.root[marketType].data = categories.map(cat => cat.id)
    },
    getMainRootCategoriesSuccess: (state, action) => {
      const { categories } = action.payload
      state.root['ALL'].status = constants.COMPLETE
      state.root['ALL'].data = categories
    },

    getRootCategoriesError: (state, action) => {
      const { marketType, error } = action.payload
      state.root[marketType].error = error
      state.root[marketType].status = constants.ERROR
    },

    getCategoriesStart: (state, action) => {
      const { marketType, parentCategoryId } = action.payload
      state.tree[marketType].status[parentCategoryId] = constants.LOADING
    },

    getCategoriesSuccess: (state, action) => {
      const { marketType, category, children, parentCategoryId } = action.payload
      const data = transformArrayIntoMap(children)
      const categoryIds = children.map(el => el.id)

      if (!state.pool.data[category.id]) {
        state.pool.data[category.id] = {}
      }

      state.pool.data = { ...state.pool.data, ...data }
      state.pool.data[category.id] = { ...state.pool.data[category.id], ...category }
      state.tree[marketType].data[category.id] = categoryIds
      state.tree[marketType].status[parentCategoryId] = constants.COMPLETE
    },

    getCategoriesError: (state, action) => {
      const { marketType, parentCategoryId, error } = action.payload
      state.tree[marketType].status[parentCategoryId] = error
      state.tree[marketType].error[parentCategoryId] = error
    },

    clearCategories: state => {
      // eslint-disable-next-line
      state = { ...initialState }
    },

    addCategoriesToPool: (state, action) => {
      const { categories } = action.payload
      if (state.pool && state.pool.data) {
        const categoryMap = transformArrayIntoMap(categories)
        state.pool.data = { ...state.pool.data, ...categoryMap }
      }
    },

    getFeaturedCategoriesStart: state => {
      state.featured.status = constants.LOADING
    },

    getFeaturedCategoriesSuccess: (state, action) => {
      const { featuredCategories } = action.payload
      state.featured.status = constants.COMPLETE
      state.featured.data = featuredCategories

      // Add featured categories to the pool
      const categoriesObj = transformArrayIntoMap(featuredCategories)
      state.pool.data = { ...state.pool.data, ...categoriesObj }
      
      // Create a special "featured" category ID to store in the tree
      const featuredCategoryId = 1 
      
      
      // Add to tree for both market types
      const categoryIds = featuredCategories.map(cat => cat.id)
      
      state.tree[constants.PLAY_MONEY].data[featuredCategoryId] = categoryIds
      state.tree[constants.REAL_MONEY].data[featuredCategoryId] = categoryIds
      state.tree['ALL'].data[featuredCategoryId] = categoryIds
       

      // Update status - ensure status is an object before setting properties
      if (typeof state.tree[constants.PLAY_MONEY].status === 'string') {
        state.tree[constants.PLAY_MONEY].status = {};
      }
      if (typeof state.tree[constants.REAL_MONEY].status === 'string') {
        state.tree[constants.REAL_MONEY].status = {};
      }
      if (typeof state.tree['ALL'].status === 'string') {
        state.tree['ALL'].status = {};
      }
      
      state.tree[constants.PLAY_MONEY].status[featuredCategoryId] = constants.COMPLETE
      state.tree[constants.REAL_MONEY].status[featuredCategoryId] = constants.COMPLETE
      state.tree['ALL'].status[featuredCategoryId] = constants.COMPLETE

      // Update root['ALL'].data to include featured categories
      if (state.root['ALL'].data.length > 0) {
        // Find if there's already an "All" category or create a new one
        const allCategoryExists = state.root['ALL'].data.find(
          cat => cat.slug === 'all' || (typeof cat === 'object' && cat.slug === 'all')
        )

        if (allCategoryExists) {
          // Update existing "All" category
          state.root['ALL'].data = state.root['ALL'].data.map(cat => {
            if ((typeof cat === 'object' && cat.slug === 'all') || cat.slug === 'all') {
              return {
                ...cat,
                children: featuredCategories,
              }
            }
            return cat
          })
        } else {
          // Add new "All" category with featured categories as children
          state.root['ALL'].data = [
            {
              id: 1,
              title: '',
              slug: '',
              parent: null,
              in_leaderboard: true,
              icon: null,
              children: featuredCategories,
            },
            ...state.root['ALL'].data,
          ]
        }
      } else {
        // If root['ALL'].data is empty, initialize it with the "All" category
        state.root['ALL'].data = [
          {
            id: 1,
            title: '',
            slug: '',
            parent: null,
            in_leaderboard: true,
            icon: null,
            children: featuredCategories,
          },
        ]
      }
    },

    getFeaturedCategoriesError: (state, action) => {
      const { error } = action.payload
      state.featured.error = error
      state.featured.status = constants.ERROR
    },
  },
})

// Selectors
export const selectCategoryRepository = () => state => state.categories.data

export const selectRootCategoriesStatus = marketType => state => {
  return state.categories.root[marketType].status
}

export const selectMainRootCategoryList = () => state => state.categories.root['ALL'].data

export const selectRootCategoryList = marketType => state => {
  const categoryList = state.categories.root[marketType].data
  if (categoryList) {
    return categoryList.map(id => state.categories.pool.data[id])
  }

  return []
}

export const selectCategoryById = categoryId => state => state.categories.pool.data[categoryId]

export const selectCategoryStatusById = (parentId, marketType) => state =>
  (state.categories.tree[marketType] && state.categories.tree[marketType].status[parentId]) || constants.IDLE

export const selectCategoriesByParentId = (parentId, marketType) => state => {
  const categoryList = state.categories.tree[marketType].data[parentId]
  if (!categoryList) {
    return []
  }

  return categoryList.map(id => state.categories.pool.data[id])
}

export const selectFeaturedCategories = () => state => state.categories.featured.data
export const selectFeaturedCategoriesStatus = () => state => state.categories.featured.status

export const {
  getRootCategoriesStart,
  getRootCategoriesSuccess,
  getMainRootCategoriesSuccess,
  getRootCategoriesError,
  getFeaturedCategoriesStart,
  getFeaturedCategoriesSuccess,
  getFeaturedCategoriesError,
} = categories.actions
export const { getCategoriesSuccess, getCategoriesStart, getCategoriesError, addCategoriesToPool, clearCategories } =
  categories.actions
export default categories.reducer

// Thunks
export const fetchRootCategories =
  (marketType, options = {}) =>
  async dispatch => {
    const currencyMode = getMarketType(marketType === constants.REAL_MONEY)
    const params = requestService.parseFilters({ ...options, currency_mode: currencyMode })
    dispatch(getRootCategoriesStart({ marketType }))
    try {
      var response = await requestService.get(`/categories/root/?${params.toString()}`)
    } catch (error) {
      dispatch(getRootCategoriesError({ marketType, error }))
      throw error
    }

    dispatch(getRootCategoriesSuccess({ marketType, categories: await response.json() }))
  }
export const fetchMainRootCategories = marketType => async dispatch => {
  dispatch(getRootCategoriesStart({ marketType: 'ALL' }))
  try {
    const currencyMode = getMarketType(marketType === constants.REAL_MONEY)
    var response = await requestService.get(`/categories/root_and_main_children/?currency_mode=` + currencyMode)
  } catch (error) {
    console.log(error)
    throw error
  }

  dispatch(getMainRootCategoriesSuccess({ categories: await response.json() }))
}

export const fetchCategories =
  (parentCategoryId, marketType, options = {}) =>
  async dispatch => {
    const currencyMode = getMarketType(marketType === constants.REAL_MONEY)
    const params = requestService.parseFilters({ ...options, currency_mode: currencyMode })

    dispatch(getCategoriesStart({ marketType, parentCategoryId }))
    try {
      var response = await requestService.get(`/categories/${parentCategoryId}/?` + params.toString())
    } catch (error) {
      dispatch(getCategoriesError({ marketType, parentCategoryId, error }))
      throw error
    }

    const { children, ...category } = await response.json()
    dispatch(getCategoriesSuccess({ marketType, parentCategoryId, category, children }))
  }

export const fetchFeaturedCategories = (marketType, options = {}) => async dispatch => {
  dispatch(getFeaturedCategoriesStart())
  try {
    const currencyMode = getMarketType(marketType === constants.REAL_MONEY)
    let url = `/categories/featured/?currency_mode=${currencyMode}`
    
    // Add search parameter if provided
    if (options.search) {
      url += `&search=${encodeURIComponent(options.search)}`
    }
    
    const response = await requestService.get(url)
    const featuredCategories = await response.json()
    dispatch(getFeaturedCategoriesSuccess({ featuredCategories }))
  } catch (error) {
    dispatch(getFeaturedCategoriesError({ error }))
    console.error('Error fetching featured categories:', error)
    throw error
  }
}
