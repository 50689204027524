/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { signupFormUrl } from 'urls'
import { GoogleIcon } from 'common/components/icons'
import { i18n } from 'common/locale/i18n'
import { exchangeAuthCodeforAccessToken, loginGoogle } from 'store/account'
import { useCurrentRouteName, useCurrentUser } from 'common/hooks'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from 'common/ui/button'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import Sentry from 'app/sentry/Sentry'

const GoogleButton = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const page = useCurrentRouteName()
  const user = useCurrentUser()
  const [error, setError] = useState()

  useEffect(() => {
    if (user) {
      if (user.username) {
        // navigate(feedUrl())
      } else {
        navigate(signupFormUrl())
      }
    } else {
      GoogleAuth.initialize({
        clientId: process.env.REACT_APP_GOOGLE_APP_ID,
        scopes: ['profile', 'email'],
        grantOfflineAccess: true,
      })
    }
  }, [user])

  const onLoginClick = async () => {
    try {
      const googleResponse = await GoogleAuth.signIn()
      if (!googleResponse.authentication || !googleResponse.authentication.accessToken) {
        const authResponse = await dispatch(exchangeAuthCodeforAccessToken(googleResponse.serverAuthCode))
        var access_token = authResponse['access_token']
      } else var access_token = googleResponse.authentication.accessToken

      await dispatch(loginGoogle(access_token))
      if (props.onLogin) props.onLogin()
    } catch (err) {
      Sentry.log(error)
      setError(i18n('login.google_error_server'))
      console.error(err)
    }
  }

  return (
    <React.Fragment>
      <Button className="gap-2 !bg-[#131314] hover:!bg-[#333338] px-3" type="solid" size="full" onClick={onLoginClick}>
        <GoogleIcon width="24px" height="24px" color={props.color} />
        <span className="text-white">
          {!props.iconOnly && (page === 'Login' ? i18n('login.google_sign_in') : i18n('login.google_sign_up'))}
        </span>
      </Button>
      {error && <div className="google-button__error">{error}</div>}
    </React.Fragment>
  )
}

export default GoogleButton
