import React from 'react'

const PlaceBetSkeleton = () => {
  return (
    <div className="bg-Neutral-1 rounded-lg md:w-[340px] p-4">
      {/* Yes/No Buttons Skeleton */}
      <div className="flex w-full gap-2 mb-6">
        <div className="h-12 w-full skeleton rounded-lg"></div>
        <div className="h-12 w-full skeleton rounded-lg"></div>
      </div>

      {/* Amount Input Skeleton */}
      <div className="mb-2">
        <div className="h-5 w-20 skeleton rounded"></div>
      </div>
      <div className="h-14 w-full skeleton rounded-lg mb-6"></div>

      {/* Info Rows Skeleton */}
      <div className="flex gap-4 my-6 justify-between">
        <div className="flex flex-col gap-2 w-1/2">
          <div className="h-4 w-24 skeleton rounded"></div>
          <div className="h-5 w-16 skeleton rounded"></div>
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <div className="h-4 w-24 skeleton rounded"></div>
          <div className="h-5 w-16 skeleton rounded"></div>
        </div>
      </div>

      {/* Button Skeleton */}
      <div className="h-12 w-full skeleton rounded-lg"></div>
    </div>
  )
}

export default PlaceBetSkeleton 