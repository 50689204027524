/* @flow */
import { BehaviorSubject } from 'rxjs'
import requestService from 'common/services/request'
import { userIsAnonymous, deleteCredential } from 'api/auth'
import { AuthenticationError } from 'common/errors'
import createFormData from '../common/util/createFormData'
import cacheService from 'common/services/cache'

class AccountService {
  meSubject = new BehaviorSubject({})
  is_calling = false
  has_data = false

  me() {
    if (!this.has_data && !this.is_calling) {
      this.retrieve().catch(() => {})
    }
    return this.meSubject
  }

  async retrieve() {
    this.is_calling = true

    if (await userIsAnonymous()) {
      throw new AuthenticationError()
    }

    try {
      cacheService.clear()
      const response = await requestService.get(`/me/`)
      this.is_calling = false

      if (response.status !== 200) {
        if (response.status === 401) {
          deleteCredential()
        }
        throw new Error(`server error${response.status}`)
      }

      const data = await response.json()
      const me = this.meSubject.getValue()
      this.has_data = true
      const newMe = { ...me, ...data }
      this.meSubject.next(newMe)
      return newMe
    } catch (err) {
      console.log(err);
      
      throw new AuthenticationError()
    }
  }

  logout() {
    this.meSubject && this.meSubject.next({})
  }

  async changeMe(data) {
    if (await userIsAnonymous()) {
      throw new AuthenticationError()
    }

    const current = this.meSubject.getValue()
    const id = current.id

    const response = await requestService.patch(`/users/${id}/`, createFormData(data))
    if (response.status !== 200) {
      throw await response.json()
    }

    const userData = response.json()
    this.meSubject.next({ ...current, ...userData })
    return { ...current, ...userData }
  }

  recoverPassword(email) {
    return this.handleRequest(`/password_reset/?email=${email}`, 'get')
  }

  retrieveUser(username) {
    return this.handleRequest(`/users/${username}/`, 'get')
  }

  retrieveUserSummary(userId) {
    return this.handleRequest(`/users/${userId}/summary/`, 'get')
  }

  follow(userId) {
    return this.handleRequest(`/users/${userId}/follow/`, 'post')
  }

  unfollow(userId) {
    return this.handleRequest(`/users/${userId}/unfollow/`, 'post')
  }

  retrieveFollowers(username) {
    return this.handleRequest(`/users/${username}/followers/`, 'get')
  }

  retrieveFollowing(username) {
    return this.handleRequest(`/users/${username}/following/`, 'get')
  }

  amIFollowingUser(userId) {
    return this.handleRequest(`/users/${userId}/am_i_following/`, 'get')
  }

  retrievePaymentMethods() {
    return this.handleRequest(`/finance/available_withdraw_accounts/`, 'get')
  }

  retrievePendingWithdrawals() {
    return this.handleRequest(`/finance/pending_withdrawals/`, 'get')
  }

  registerPushNotification(device, token) {
    return this.handleRequest(`/push_subscription/`, 'post', {
      type: 'fcm',
      device,
      endpoint: token,
    })
  }

  handleRequest = (endpoint, method, data = {}) =>
    new Promise((resolve, reject) => {
      cacheService.clear()
      requestService[method](endpoint, data).then(response => {
        if (response.status !== 200) {
          return response.json().then(data => {
            reject({
              message: `server error${response.status}`,
              status: response.status,
              errors: { ...data },
            })
          })
        }
        return resolve(response.json())
      })
    })
}

const userCanBetWithRealMoney = user => {
  return user && user.from_allowed_country
}

const instance = new AccountService()
export default instance

class UserResource {
  id = null
}

export { UserResource, userCanBetWithRealMoney }
