/* @flow */
import React from 'react'
import { Link } from 'react-router-dom'
import { categoryListUrl, keepQueryString } from 'urls'
import { RightArrowIcon } from 'common/components/icons'
import './CategoryList.scss'

function CategoryList({ categories }) {
  const itemClick = event => {
    event.stopPropagation()
  }

  return (
    <div className="category-list">
      {categories &&
        Array.isArray(categories) &&
        categories.map((category, index) => {
          const isLast = index === categories.length - 1

          return (
            <React.Fragment key={`category-${category.id || index}`}>
              <Link
                to={keepQueryString(categoryListUrl(category))}
                className="category-list__item whitespace-nowrap"
                onClick={itemClick}>
                {category.title || category}
              </Link>

              {!isLast && (
                <span className="category-list__separator">
                  <RightArrowIcon width="5px" />
                </span>
              )}
            </React.Fragment>
          )
        })}
    </div>
  )
}

export default CategoryList
