/* @flow */
import React from 'react'
//FIXME: This import is horrendous, but we have no time left
import { i18n } from 'common/locale/i18n'
import { EyeIcon } from 'common/components/icons'

class MarketFollow extends React.PureComponent {
  render() {
    const { marketTitle, is_followed } = this.props
    return (
      <div className="flex items-center">
        {is_followed ? <EyeIcon outline width="48px" /> : <EyeIcon width="48px" />}
        <div className="message__text">
          <div className="message__main-text">
            {is_followed ? i18n('bet.unfollow') : i18n('bet.follow')}
            <br />
            <div className="message__main--second-text">{marketTitle}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default MarketFollow
