import React, { useEffect, useRef, useState, lazy, Suspense } from 'react'
import { Route, useLocation, matchPath, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Device from 'common/util/Device'
// import ErrorHandler from './error-handler'
import MessageBar from 'notifications/components/message-bar'
import MixpanelBrowser from 'mixpanel-browser'
import RouteController from './RouteController'
import ScrollToTop from 'app/components/scroll-to-top'
import classNames from 'classnames'
import constants from 'app/constants'
import routes from 'routes'
import storage from 'common/util/storage'
import { userIsAnonymous } from 'api/auth'
import { feedUrl } from 'urls'
import mixpanelService from 'app/analytics/Mixpanel'

import {
  fetchExchangeRates,
  fetchWagerConstants,
  selectActiveCurrency,
  selectExchangeRateStatus,
  selectPageLayout,
  selectWagerConstantsStatus,
  setActiveCurrency,
  setAppLoading,
  setMarketType,
} from 'store/app'
import {
  useCapacitorPushNotifications,
  useCurrentUser,
  useFacebookApi,
  useRealTimeAuthModel,
  useCurrencies,
  useRealTimeMarket,
  useAppTheme,
  useABTests,
  useChatBot,
} from 'common/hooks'
import {
  fetchCurrenciesProproties,
  fetchCurrentUser,
  fetchUserInAllowedCountry,
  selectCurrentUserStatus,
  selectUserFromAllowedCountry,
} from 'store/account'
import { refreshToken } from 'store/account'
import { addParamsToGTM } from 'app/analytics'
import { fetchUnreadNotificationCount, selectUnreadNotificationCountStatus } from 'store/notifications'
import { urlSearchParams } from 'common/util/urlSearchParams'

import './App.scss'
import { ToastContainer } from 'react-toastify'
import useIsMobileSize from 'common/hooks/useIsMobileSize'
import SuspensePanel from 'common/components/suspense'

// Lazy loaded components
const Footer = lazy(() => import('app/components/Footer'))
const Navbar = lazy(() => import('app/components/navbar'))
const Tabbar = lazy(() => import('app/components/Tabbar'))
const ErrorHandler = lazy(() => import('./error-handler'))
const GlobalComponent = lazy(() => import('./global-component'))
const ViewOffline = lazy(() => import('views/offline'))
const OnboardingTour = lazy(() => import('onboarding/components/OnboardingTour'))

const className = classNames('app', {
  'app--cordova': Device.isCordova,
  'app--ios': Device.isIOS,
  'app--android': Device.isAndroid,
})

const App = () => {
  useCapacitorPushNotifications()
  useFacebookApi()
  useChatBot()
  const { initTheme } = useAppTheme()
  const MAINTANANCE_MODE = false
  const { fetchAndSyncTests } = useABTests()
  const isMobileSize = useIsMobileSize()
  const dispatch = useDispatch()
  const activeCurrency = useSelector(selectActiveCurrency)
  const location = useLocation()
  const currentLocation = useRef()
  const currentUser = useCurrentUser()
  const currentUserStatus = useSelector(selectCurrentUserStatus)
  const fromAllowedCountry = useSelector(selectUserFromAllowedCountry())
  const exchangeRate = useCurrencies()
  const wagerConstantsStatus = useSelector(selectWagerConstantsStatus)
  const unreadNotificationStatus = useSelector(selectUnreadNotificationCountStatus())
  const pageLayout = useSelector(selectPageLayout)
  const isLoadingConstants = wagerConstantsStatus === constants.LOADING || wagerConstantsStatus === constants.IDLE
  const [isLoadingUser, setLoadingUser] = useState(true)
  const exchangeRateStatus = useSelector(selectExchangeRateStatus())

  useRealTimeMarket()

  const isAuthenticationRoute = () => location.pathname.startsWith('/l/')
  const isModalRoute = () => location.pathname.startsWith('/m/')
  const isPublicFollowUrl = () => matchPath({ path: '/:username/follow' }, location.pathname)

  // Custom hook for real-time authentication model management.
  useRealTimeAuthModel({
    // The model is set based on the current user's wallet, if available.
    model: currentUser && currentUser.wallet ? currentUser.wallet : {},
    eventName: 'balance-changed', // Event name to listen for.
    onReceiveMessage: () => {
      dispatch(fetchCurrentUser()) // Action to fetch current user data on receiving a message.
    },
  })

  //  initialize the app's theme.
  useEffect(() => {
    fetchAndSyncTests()
  }, [])
  //  initialize the app's theme.
  useEffect(() => {
    initTheme()
  }, [])

  //  manage the app's loading state.
  useEffect(() => {
    // Dispatches action to set app loading state based on various conditions.
    dispatch(setAppLoading(isLoadingUser || fromAllowedCountry === null || !exchangeRate || isLoadingConstants))
  }, [isLoadingUser, fromAllowedCountry, exchangeRate, isLoadingConstants])

  // fetching user's country allowance status.
  useEffect(() => {
    dispatch(fetchUserInAllowedCountry())
  }, [])

  // adding user ID to Google Tag Manager.
  useEffect(() => {
    // Adds user ID parameters to GTM if currentUser exists and has an ID.
    if (currentUser && currentUser.id) addParamsToGTM({ user_id: currentUser.id })
  }, [currentUser])

  //  fetching wager constants.
  useEffect(() => {
    dispatch(fetchWagerConstants())
  }, [])

  //  fetching exchange rates and currency properties.
  useEffect(() => {
    // Checks if exchangeRateStatus is IDLE, then fetches exchange rates and currency properties.
    if (exchangeRateStatus === constants.IDLE) {
      dispatch(fetchExchangeRates())
      dispatch(fetchCurrenciesProproties())
    }
  }, [])

  //   fetching unread notification count.
  useEffect(() => {
    // Fetches unread notification count if currentUser exists and has an ID, and if unreadNotificationStatus is IDLE.
    if (currentUser && currentUser.id && unreadNotificationStatus === constants.IDLE) {
      dispatch(fetchUnreadNotificationCount())
    }
  }, [currentUser])

  //  handle user authentication and currency settings based on country allowance.
  useEffect(() => {
    // Only proceed if fromAllowedCountry has been determined (i.e., is not null).
    if (fromAllowedCountry !== null) {
      userIsAnonymous().then(async isAnonymous => {
        // Proceed only if the user is not anonymous.
        if (!isAnonymous) {
          // Retrieves the last real money currency used from storage.
          const lastRealMoneyCurrency = await storage('lastRealMoneyCurrency').get()
          // Retrieves the user's last currency choice from storage.
          const lastCurrency = await storage('userCurrency').get()
          // Refreshes the user's authentication token.
          await dispatch(refreshToken())
          // Determines the default currency based on the user's location and device type.
          const defaultCurrency = fromAllowedCountry && !Device.isCordova ? constants.REAL_MONEY_PLACEHOLDER : 'OOM'
          // Sets the active currency if no last currency settings are found.
          if (!lastRealMoneyCurrency && !lastCurrency) {
            dispatch(setActiveCurrency(defaultCurrency))
          }
        } else {
          dispatch(setActiveCurrency(constants.REAL_MONEY_PLACEHOLDER))
        }
        // Sets the loading user state to false, indicating completion of the process.
        setLoadingUser(false)
      })
    }
  }, [fromAllowedCountry]) // This effect runs when fromAllowedCountry changes.

  // parse and store URL parameters for analytics.
  useEffect(() => {
    // Creates a new URLSearchParams object to parse the query string of the URL.
    const urlStructure = new urlSearchParams(window.location.search)
    // List of analytics parameters to look for in the URL.
    const analyticsParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']
    // Object to store the found analytics parameters.
    let output = {}
    // Iterates over the analyticsParams array.
    analyticsParams.forEach(key => {
      // Retrieves the value for each analytics parameter in the URL, if present.
      const content = urlStructure.get(key)

      // If the parameter is found in the URL, add it to the output object.
      if (content) output[key] = content
    })
    // Converts the output object into a JSON string.
    const strOutput = JSON.stringify(output)
    // Stores the JSON string of analytics parameters in local storage under 'utms'.
    storage('utms').set(strOutput)
  }, [])
  useEffect(() => {
    setTimeout(() => {
      // if otheres fail we default to 30 seconds
      if (!window.prerenderReady) window.prerenderReady = true
    }, 30000)
  }, [])

  useEffect(() => {
    const getUserCurrency = currency => {
      // If the currency selected is zero we should switch to another one
      if (currency === 'OOM' || currentUser.wallet[currency]) return currency
      for (let key in currentUser.wallet) {
        if (key !== 'OOM' && currentUser.wallet[key] !== 0) {
          return { key: key, value: currentUser.wallet[key] }
        }
      }
      return null
    }

    if (currentUser && !pageLayout.appLoading)
      storage('userCurrency')
        .get()
        .then(async storedCurrency => {
          const lastRealMoneyCurrency = await storage('lastRealMoneyCurrency').get()

          const CountryCurrency =
            fromAllowedCountry && !Device.isCordova ? lastRealMoneyCurrency || constants.REAL_MONEY_PLACEHOLDER : 'OOM'

          // Check if storedCurrency is a placeholder value
          const isStoredCurrencyPlaceholder =
            storedCurrency === constants.REAL_MONEY || storedCurrency === constants.REAL_MONEY_PLACEHOLDER

          // Determine currency with proper priority
          let currency

          if (!isStoredCurrencyPlaceholder && storedCurrency) {
            // Case 1: We have a stored currency that's not a placeholder - highest priority
            currency = storedCurrency
          } else if (currentUser && getUserCurrency(currentUser.settings.last_currency_selected)) {
            // Case 2: Use the user's last selected currency - second priority
            currency = getUserCurrency(currentUser.settings.last_currency_selected)
          } else if (isStoredCurrencyPlaceholder && storedCurrency) {
            // Case 3: We have a stored currency that IS a placeholder - third priority
            currency = storedCurrency
          } else {
            // Case 4: Fallback to country currency - lowest priority
            currency = CountryCurrency
          }
          const marketType = currency === 'OOM' ? constants.PLAY_MONEY : constants.REAL_MONEY

          dispatch(setActiveCurrency(currency))
          dispatch(setMarketType(marketType))
        })
  }, [pageLayout.appLoading, currentUser, fromAllowedCountry])

  useEffect(() => {
    if (activeCurrency !== 'OOM') {
      dispatch(fetchExchangeRates())
    }
    const interval = setInterval(() => {
      if (activeCurrency !== 'OOM') {
        dispatch(fetchExchangeRates())
      }
    }, 1000 * 60)

    return () => {
      clearInterval(interval)
    }
  }, [activeCurrency])

  useEffect(() => {
    if (currentUserStatus !== constants.COMPLETE) return
    try {
      const isBot = navigator.userAgent.match(/.*((AhrefsSiteAudit)|(AhrefsBot)).*/i)
      if (process.env.REACT_APP_MIXPANEL_ID && !isBot) {
        MixpanelBrowser.init(process.env.REACT_APP_MIXPANEL_ID, { track_pageview: false })
      }
    } catch (error) {
      console.error('Mixpanel init error', error)
    }
  }, [currentUserStatus])

  useEffect(() => {
    if (currentUser && currentUser.id) mixpanelService.identify(currentUser.id)
  }, [currentUser])
  // useEffect(() => {
  //   if (pageLayout.appLoading) return
  //   if (isInSubCategory() === null && !isAuthenticationRoute() && !isInTagsPage()) {
  //     dispatch(fetchRootCategories(activeMarketType))
  //   }
  // }, [pageLayout.appLoading, activeMarketType, activeLanguage, location])

  // useEffect(() => {
  //   if (pageLayout.appLoading) return

  //   if (!isAuthenticationRoute()) {
  //     dispatch(fetchMainRootCategories(activeMarketType))
  //   }
  // }, [pageLayout.appLoading, activeMarketType, location])

  // SHOW NOTIFICATION

  // NAVBAR
  const shouldDisplayNavbar = () => {
    if (Device.isMobile) {
      return !isAuthenticationRoute() && !isModalRoute() && !isPublicFollowUrl()
    }
    return true
  }

  const shouldDisplayTabbar = () => {
    return !isAuthenticationRoute() && !isModalRoute()
  }

  const renderRoutes = routes => {
    return routes.map(route => {
      if (Array.isArray(route.path)) {
        return route.path.map((p, idx) => <Route key={`${p}-${idx}`} path={p} element={route.element} />)
      } else {
        return <Route key={`route-to-${route.path}`} path={route.path} element={route.element} />
      }
    })
  }

  const RenderAppSwitch = () => {
    const location = useLocation()

    const pageRoutes = routes.filter(route => !route.isModal)
    const modalRoutes = routes.filter(route => route.isModal)
    const isModal = modalRoutes.find(route => matchPath(route.path, location.pathname))
    let modalLocation = { ...location }

    if (isModal) {
      if (currentLocation.current) {
        const { pathname, search } = currentLocation.current
        const returnUrl = search ? `${pathname}/${search}` : pathname
        modalLocation = { ...modalLocation, state: { returnUrl } }
      } else {
        currentLocation.current = { ...location, pathname: feedUrl() }
      }
    } else {
      currentLocation.current = location
    }

    if (MAINTANANCE_MODE || currentLocation.current.pathname === '/offline') {
      return (
        <Suspense fallback={<SuspensePanel page />}>
          <ViewOffline />
        </Suspense>
      )
    }

    return (
      <>
        <Suspense fallback={<SuspensePanel />}>
          <Routes location={modalLocation}>{renderRoutes(modalRoutes)}</Routes>
        </Suspense>
        <Suspense fallback={<SuspensePanel page />}>
          <Routes location={currentLocation.current}>{renderRoutes(pageRoutes)}</Routes>
        </Suspense>
      </>
    )
  }

  return (
    <RouteController>
      <ScrollToTop />
      <ToastContainer stacked={isMobileSize} position="bottom-right" />

      <div className={className}>
        <ErrorHandler location={location}>
          <MessageBar me={currentUser} history={history} />
        </ErrorHandler>

        <ErrorHandler location={location}>{shouldDisplayNavbar() ? <Navbar me={currentUser} /> : null}</ErrorHandler>
        <ErrorHandler location={location}>{RenderAppSwitch()}</ErrorHandler>
        <ErrorHandler location={location}> {shouldDisplayTabbar() ? <Tabbar me={currentUser} /> : null}</ErrorHandler>

        <div id="modal" className="app__modal" />
      </div>

      <GlobalComponent />
      {pageLayout.displayFooter && (
        <ErrorHandler location={location}>
          <Footer />
        </ErrorHandler>
      )}

      <ErrorHandler location={location}>
        {currentUser &&
          currentUser.settings &&
          !currentUser.settings.seen_onboarding_app &&
          currentUser.username_confirmed && (
            <Suspense fallback={<div className="loading-placeholder">Loading onboarding tour...</div>}>
              <OnboardingTour isFromAllowedCountry={fromAllowedCountry} isEmailVerified={currentUser.email_confirmed} />
            </Suspense>
          )}
      </ErrorHandler>
    </RouteController>
  )
}

export default App
