import { SuccessIcon } from 'common/components/icons'
import React from 'react'

class FeedbackSent extends React.PureComponent {
  render() {
    return (
      <div className="flex items-center">
        <SuccessIcon />

        <div className="message__text">
          <div className="message__main-text">Feedback sent. Thanks!</div>
        </div>
      </div>
    )
  }
}

export default FeedbackSent
