// Import required dependencies
import React, { useEffect, useState } from 'react'
import { i18n } from 'common/locale/i18n'
import MarketListHeader from 'explore/components/active-markets'
import FilterDropdownPanel from 'common/components/filter-dropdown-panel'
import InfiniteGrid from 'common/components/InfiniteGrid'
import constants from 'app/constants'
import Modal from 'common/components/modal/Modal'

// Commented out unused import
// import Market from 'questions/components/market'

// Import styles and components
import './MarketListTemplate.scss'
import MarketCard from '../market-card/MarketCard'
import { ArrowUpDown } from 'common/components/icons'
import { RadioControl } from 'common/components/form-controls'
import ActionSheetLayout from 'layouts/components/action-sheet-layout'
import { urlSearchParams } from 'common/util/urlSearchParams'
import { useNavigate } from 'react-router-dom'
import useIsMobileSize from 'common/hooks/useIsMobileSize'

/**
 * MarketListTemplate Component
 * Renders a list of markets with filtering, sorting and infinite scroll capabilities
 *
 * @param {string} activeMarketType - Type of market (real money vs play money)
 * @param {object} queryParams - URL query parameters for filtering
 * @param {array} markets - Array of market data
 * @param {object} pagination - Pagination information
 * @param {string} status - Current loading status
 * @param {object} error - Error object if any
 * @param {function} onLoadMore - Callback for loading more markets
 * @param {function} setMarketSelected - Callback to select a market
 * @param {function} setOutcomeSelected - Callback to select an outcome
 * @param {object} marketSelected - Currently selected market
 * @param {object} OutcomeSelected - Currently selected outcome
 * @param {string} page - Current page identifier
 * @param {object} breakpoints - Breakpoints for responsive layout
 * @param {function} onBreakpointChange - Callback when breakpoint changes
 */
const MarketListTemplate = ({
  activeMarketType,
  queryParams,
  markets,
  pagination,
  status,
  error,
  onLoadMore,
  setMarketSelected = () => {},
  setOutcomeSelected = () => {},
  marketSelected,
  OutcomeSelected,
  page = 'feed',
  breakpoints = { default: 4, 1920: 4, 1440: 3, 1024: 2, 981: 1 },
  onBreakpointChange = () => {},
}) => {
  // State management
  const [isLoadingFirstTime, setIsLoadingFirstTime] = useState(true)
  const [sort, setSort] = useState()
  const [sortModalOpen, setSortModalOpen] = useState(false)
  // Add state to track which market has its bet list open
  const [openBetListMarketId, setOpenBetListMarketId] = useState(null)
  const navigate = useNavigate()
  const isMobileSize = useIsMobileSize()
  const params = new urlSearchParams(location.search)
  const isLoading = status === constants.LOADING || status === constants.IDLE

  // Update loading state when data fetching completes
  useEffect(() => {
    if (status === constants.COMPLETE) {
      setIsLoadingFirstTime(false)
    }
  }, [status])

  // Set prerender flag when markets are loaded
  useEffect(() => {
    if (!isLoading && markets.length > 0) {
      setTimeout(() => {
        window.prerenderReady = true
      }, 1000)
    }
  }, [isLoading, markets])

  /**
   * Handles toggling the bet list for a market
   * @param {string} marketId - ID of the market to toggle
   * @param {boolean} isCollapsed - Current collapse state
   */
  const handleToggleBetList = (marketId, isCollapsed) => {
    // If the bet list is being opened, set this market as the open one
    // If it's being closed, clear the open market ID
    setOpenBetListMarketId(isCollapsed ? null : marketId)
  }

  /**
   * Renders individual market card
   * @param {object} param0 - Contains index of the market to render
   */
  const onRender = ({ index }) => {
    const market = markets[index]
    if (!market) return

    return (
      <MarketCard
        key={'question-card-' + market.id}
        question={market}
        categories={market && market.category}
        inlineBehavior
        showBetStatus
        showSelectedOutcome
        isSelectedToBet={market && marketSelected && marketSelected.id === market.id}
        currentOutcomeSelection={OutcomeSelected}
        onOutcomeSelected={(outcome, position) => {
          setOutcomeSelected(outcome, position)
          setMarketSelected(market)
        }}
        setMarketSelected={setMarketSelected}
        // Pass the forced collapse state based on whether this market is the one with open bet list
        forceBetListCollapsed={openBetListMarketId !== null && openBetListMarketId !== market.id}
        // Pass the handler for toggling bet list
        onToggleBetList={isCollapsed => handleToggleBetList(market.id, isCollapsed)}
      />
    )
  }

  /**
   * Renders skeleton loading cards
   * Used when markets are being fetched
   */
  const onRenderSegments = () => {
    // Use consistent height of 3 outcomes for all skeleton cards
    return (
      <>
        {[1, 2, 1, 3, 1, 2, 2].map((_, i) => (
          <div className="market-page__card-skeleton " key={'skeleton' + i}>
            <div className="market-page__card-skeleton--header skeleton"></div>

            <div className="market-page__card-skeleton--outcomes ">
              {_ === 1 ? (
                <div className="flex gap-4">
                  <div className="market-page__card-skeleton--outcome skeleton"></div>
                  <div className="market-page__card-skeleton--outcome skeleton"></div>
                </div>
              ) : (
                [...Array(_).keys()].map(_ => (
                  <div key={_} className="market-page__card-skeleton--outcome skeleton"></div>
                ))
              )}
            </div>
            <div className="market-page__card-skeleton--footer ">
              <div className="market-page__card-skeleton--footer--details skeleton"></div>
              <div className="market-page__card-skeleton--footer--details skeleton"></div>
            </div>
          </div>
        ))}
      </>
    )
  }

  /**
   * Renders the sorting dropdown component
   * Allows users to sort markets by different criteria
   */
  const renderSortDropdown = () => {
    const hideModal = () => {
      setSortModalOpen(false)
    }
    const renderList = () => {
      const sortByOptions = [
        { value: '-created_on', text: i18n('markets.most_recent') },
        { value: 'bet_end_date', text: i18n('markets.bet_end_date') },
        { value: '-wagers_count', text: i18n('markets.number_of_bets') },
        { value: '-volume', text: i18n('markets.volume') },
        { value: '-relevance', text: i18n('markets.relevance') },
      ]
      return (
        <ul>
          {sortByOptions.map((header, index) => (
            <li
              key={'sort-portfolio-dropdown' + index}
              className="flex gap-2 px-2 py-3"
              onClick={() => {
                // Update URL with new sort parameter
                const _queryParams = Object.fromEntries(new urlSearchParams(location.search))
                _queryParams.ordering = header.value
                setSort(header.value)
                navigate(location.pathname + '?' + new URLSearchParams(_queryParams).toString())
                hideModal()
              }}>
              <RadioControl active={sort === header.value} />
              <div className="flex items-center gap-2 cursor-pointer">{header.text}</div>
            </li>
          ))}
        </ul>
      )
    }
    return (
      <>
        <div
          onClick={() => setSortModalOpen(true)}
          className="rounded-full flex w-10 h-10 items-center justify-center bg-Neutral-1 border border-solid border-Neutral-3">
          <ArrowUpDown width="13px" fill="currentColor" />
        </div>

        <Modal isOpen={sortModalOpen} position="bottom" onCloseRequested={hideModal}>
          <ActionSheetLayout
            title={i18n('markets.sort_by')}
            content={renderList()}
            onClose={hideModal}
            showCloseButton
          />
        </Modal>
      </>
    )
  } 
  
  return (
    <React.Fragment>
      {/* Header section with filters and sort options */}
      <div>
        <MarketListHeader
          isLoading={isLoading}
          numberOfActiveBets={!isLoading && pagination.total}
          isRealMoney={activeMarketType === constants.REAL_MONEY}
          title={params.get('search')}
          filters={
            !isLoadingFirstTime && (
              <div className="flex justify-between gap-2 w-full items-center">
                <FilterDropdownPanel
                  className="market-page__filters  md:flex-wrap max-h-[calc(100vh-100px)] md:max-h-none pb-32 md:pb-0"
                  queryParams={queryParams}
                  page={page}
                  containerClass="!p-0  "
                />
                {isMobileSize && renderSortDropdown()}
              </div>
            )
          }
        />
      </div>

      {/* Infinite scrolling grid of market cards */}
      <InfiniteGrid
        className="market-page__wrapper !px-4 md:!px-[18px] !mb-0 pb-16"
        columnClassName="market-page__column"
        error={error}
        isLoading={isLoading}
        loadedRows={isLoading && markets.length === 0 ? breakpoints[Object.keys(breakpoints).find(bp => window.innerWidth <= bp) || 'default'] : markets.length}
        onLoadMore={options => onLoadMore(activeMarketType, options)}
        onRowRender={isLoading && markets.length === 0 ? onRenderSegments : onRender}
        placeholder={
          Object.keys(queryParams).length > 0 ? i18n('markets.no_markets_clear_filters') : i18n('markets.no_markets')
        }
        totalRows={pagination ? pagination.total : 0}
        useMasonry
        autoLoading={false}
        breakpoints={breakpoints}
        onBreakpointChange={onBreakpointChange}
      />
    </React.Fragment>
  )
}

export default MarketListTemplate
