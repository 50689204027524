/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import './CategoryNavigation.scss'
import { NavLink, useLocation } from 'react-router-dom'
import { RightArrowIcon } from 'common/components/icons'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { feedUrl } from 'urls'
import useIsMobileSize from 'common/hooks/useIsMobileSize'
import OverlayPanel from 'common/components/OverlayPanel/OverlayPanel'
import Slider from 'common/ui/slider/Slider'

const CategoryNavigation = ({ parent, categories, subcategories, fullPath = '', currentSubcategory }) => {
  const [animationParent] = useAutoAnimate()
  const location = useLocation()
  const navRef = useRef(null)
  const [sliderStyle, setSliderStyle] = useState({
    left: 0,
    width: 0,
    opacity: 0,
  })
  const [isVisible, setIsVisible] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)
  const isMobileSize = useIsMobileSize()
  const [showCategoryOverlay, setShowCategoryOverlay] = useState(false)
  const overlayRef = useRef(null)

  const sliderSettings = {
    slidesPerView: 'auto',
    freeMode: true,
    watchOverflow: true,
    observer: true,
    observeParents: true,
    touchRatio: 1,
    resistance: true,
    resistanceRatio: 0.85,
    spaceBetween: 0,
    grabCursor: true,
    preventClicks: false,
    preventClicksPropagation: false,
    slideToClickedSlide: false,
    allowTouchMove: true,
  }

  const updateSliderPosition = () => {
    // Skip slider position updates on mobile devices
    if (isMobileSize) {
      console.log('isMobileSize', isMobileSize);
      return
    }

    if (navRef.current) {
      const activeNavLink = navRef.current.querySelector('.active-nav-link')

      if (activeNavLink) {
        const navRect = navRef.current.getBoundingClientRect()
        const activeRect = activeNavLink.getBoundingClientRect()

        setSliderStyle({
          left: activeRect.left - navRect.left,
          width: activeRect.width,
          opacity: 1,
        })

        // Scroll the active element into view with some offset
        const scrollContainer = navRef.current
        const scrollLeft = activeRect.left - navRect.left - navRect.width / 2 + activeRect.width / 2

        // Only scroll if needed
        if (scrollLeft > 0 || activeRect.left < navRect.left || activeRect.right > navRect.right) {
          scrollContainer.scrollTo({
            left: scrollLeft,
            behavior: 'smooth',
          })
        }
      }
    }
  }

  // Handle scroll events to show/hide navigation
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY

      // Determine scroll direction and update visibility
      if (currentScrollY > lastScrollY && currentScrollY > 150) {
        // Scrolling down and past threshold - hide nav
        setIsVisible(false)
      } else {
        // Scrolling up - show nav
        setIsVisible(true)
      }

      setLastScrollY(currentScrollY)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollY])

  // Update the slider position when the active element changes
  useEffect(() => {
    // Only update slider position on desktop
    if (!isMobileSize) {
      updateSliderPosition()
    }
  }, [location.pathname, isMobileSize])

  // Add resize observer to update position when window size changes
  useEffect(() => {
    const handleResize = () => {
      // Only update slider position on desktop
      if (!isMobileSize) {
        updateSliderPosition()
      }
    }

    window.addEventListener('resize', handleResize)

    // Initial position update after component mounts - only on desktop
    if (!isMobileSize) {
      setTimeout(updateSliderPosition, 100)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isMobileSize])

  // Effect to close overlay when clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setShowCategoryOverlay(false)
      }
    }

    if (showCategoryOverlay) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showCategoryOverlay])

  const renderSubCategories = () => {
    const subcat = []

    if (currentSubcategory) {
      // Check if we need to show ellipsis on mobile
      if (currentSubcategory.tree && currentSubcategory.tree.length >= 3) {
        // Create overlay content with full category path
        const overlayContent = (
          <div className="flex flex-col gap-4 max-w-[250px]">
            {currentSubcategory.tree.map((cat, index) =>
              // Only render items that are not the last one in the tree
              index < currentSubcategory.tree.length - 1 ? (
                <NavLink
                  key={`tree-cat-${cat.id || index}`}
                  className="py-2 px-3 rounded-lg whitespace-nowrap text-Neutral-6 hover:text-[var(--color-brand-04)]"
                  to={`/markets/${
                    currentSubcategory.tree && currentSubcategory.tree.length > 0
                      ? currentSubcategory.tree
                          .slice(0, index + 1)
                          .map(c => c.slug)
                          .join('/')
                      : ''
                  }`}
                  onClick={() => setShowCategoryOverlay(false)}>
                  {cat.title}
                </NavLink>
              ) : null
            )}
          </div>
        )

        // Add ellipsis with overlay
        subcat.push(
          <div key="category-ellipsis" className="flex items-center text-[var(--color-brand-04)]  " ref={overlayRef}>
            <OverlayPanel
              content={overlayContent}
              position="bottom-middle-up-left"
              hideOnLeave={false}
              className="!mt-4 border border-Neutral-3 border-solid !p-3"
              isVisible={showCategoryOverlay}
              onHide={() => setShowCategoryOverlay(false)}>
              <div
                className="py-3 px-4 rounded-lg mr-4 h-min whitespace-break-spaces bg-Neutral-1 text-[var(--color-brand-04)] border border-[var(--color-brand-04)] border-solid cursor-pointer"
                onClick={() => setShowCategoryOverlay(!showCategoryOverlay)}>
                ...
              </div>
            </OverlayPanel>
            {subcategories && <RightArrowIcon width="5px" />}
          </div>
        )
      }

      // Show current subcategory as before
      subcat.push(
        <div className="flex item-center text-[var(--color-brand-04)] mr-3">
          <NavLink
            key={`category-link-${currentSubcategory.id}`}
            className={navData =>
              navData.isActive
                ? 'py-3 px-4 rounded-lg mr-4 h-min whitespace-nowrap bg-Neutral-1 text-[var(--color-brand-04)] border border-[var(--color-brand-04)] border-solid'
                : 'py-3 px-4 rounded-lg mr-4 h-min whitespace-nowrap bg-Neutral-1 text-Neutral-6 hover:bg-Neutral-1 hover:text-Neutral-6'
            }
            to={
              '/markets/' + (currentSubcategory?.tree?.length ? currentSubcategory.tree.map(c => c.slug).join('/') : '')
            }>
            {currentSubcategory.title}
          </NavLink>
          {subcategories && subcategories.length > 0 && <RightArrowIcon width="5px" />}
        </div>
      )
    }

    if (subcategories && subcategories.length > 0)
      subcategories?.forEach(category => {
        subcat.push(
          <NavLink
            key={`category-link-${category.id}`}
            className={navData =>
              navData.isActive
                ? 'py-3 px-4 rounded-lg h-min whitespace-nowrap flex bg-Neutral-1 text-Neutral-6 border border-[var(--color-brand-04)] border-solid'
                : 'py-3 px-4 rounded-lg h-min whitespace-nowrap flex bg-Neutral-1 text-Neutral-6 hover:bg-Neutral-1 hover:text-Neutral-6'
            }
            to={`${fullPath}/${fullPath === '/markets' ? category?.tree?.map(c => c.slug).join('/') : category?.slug}/${
              category?.id
            }`}>
            {category?.title}
          </NavLink>
        )
      })

    return subcat
  }

  const renderCategoryNavItems = () => {
    const navItems = []
    let allCategoryLink = null
    let tagsLink = null

    // Add category links
    if (categories && categories.length > 0) {
      categories.forEach(category => {
        // Check if this is the "All" category (title is null or empty)
        const isAllCategory = !category.title || category.title === ''
        const displayTitle = isAllCategory ? 'All' : category.title

        if (isAllCategory) {
          // Keep "All" outside the list
          allCategoryLink = (
            <NavLink
              key={`category-link-${category.id}`}
              className={navData =>
                navData.isActive
                  ? 'md:py-4 py-3 px-6 font-semibold text-[var(--color-brand-04)] active-nav-link inline-block whitespace-nowrap'
                  : 'md:py-4 py-3 px-6 font-semibold text-Neutral-4 hover:text-[var(--color-brand-04)] inline-block whitespace-nowrap'
              }
              to="/markets/"
              end={true}>
              {displayTitle}
            </NavLink>
          )
        } else {
          // Add other categories to the list
          navItems.push(
            <li key={`category-item-${category.id}`}>
              <NavLink
                key={`category-link-${category.id}`}
                className={navData =>
                  navData.isActive
                    ? 'md:py-4 py-3 px-6 font-semibold text-[var(--color-brand-04)] active-nav-link inline-block whitespace-nowrap'
                    : 'md:py-4 py-3 px-6 font-semibold text-Neutral-4 hover:text-[var(--color-brand-04)] inline-block whitespace-nowrap'
                }
                to={`/markets/${category.slug}/`}
                end={false}>
                {displayTitle}
              </NavLink>
            </li>
          )
        }
      })
    }

    // Keep "Tags" link outside the list
    tagsLink = (
      <NavLink
        key={`link-tag`}
        className={navData =>
          navData.isActive
            ? 'md:py-4 py-3 px-6 font-semibold text-[var(--color-brand-04)] active-nav-link inline-block'
            : 'md:py-4 py-3 px-6 font-semibold text-Neutral-4 hover:text-[var(--color-brand-04)] inline-block'
        }
        to="/tags">
        Tags
      </NavLink>
    )

    // Return the complete navigation with All at the beginning, then the list, then Tags
    return (
      <div className="flex items-center justify-center">
        {allCategoryLink}
        {navItems.length > 0 && <ul className="flex list-none p-0 m-0  ">{navItems}</ul>}
        {tagsLink}
      </div>
    )
  }

  return (
    <>
      <nav
        className={`category-navigation !sticky top-[96px] z-10 bg-[var(--color-surface-1)] transition-transform duration-300 ${
          !isVisible ? '-translate-y-full' : 'translate-y-0'
        }`}
        ref={animationParent}>
        {categories && categories.length > 0 && (
          <div
            className="flex overflow-x-auto !px-4 scrollbar-hide snap-x md:justify-center relative"
            style={{ WebkitOverflowScrolling: 'touch' }}
            ref={navRef}>
            {renderCategoryNavItems()}
            <div
              className="sliding-border absolute bottom-0 h-0.5 bg-[var(--color-brand-04)] transition-all duration-300 ease-in-out"
              style={{
                left: `${sliderStyle.left}px`,
                width: `${sliderStyle.width}px`,
                opacity: sliderStyle.opacity,
              }}
            />
          </div>
        )}
      </nav>
      {location.pathname !== feedUrl() && categories && categories.length > 0 && (
        <div
          className={`category-navigation category-navigation--subcategories relative z-[9] min-h-12 !mb-4 !mt-4 transition-transform duration-300 ${
            !isVisible ? '-translate-y-full' : 'translate-y-0'
          }`}>
          <Slider
            type="horizontal-scroll"
            settings={{
              ...sliderSettings,
              spaceBetween: 8,
            }}
            name="subcategory-nav-slider">
            {renderSubCategories().map((item, index) => (
              <div key={`subnav-slide-${index}`} className="swiper-slide" style={{ width: 'auto' }}>
                {item}
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  )
}

CategoryNavigation.propTypes = {
  parent: PropTypes.objectOf(
    PropTypes.objectOf({ tree: PropTypes.arrayOf({ title: PropTypes.string, href: PropTypes.string }) })
  ),
  subcategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      href: PropTypes.string,
    })
  ),
}

export default CategoryNavigation
