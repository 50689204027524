import React, { Suspense, lazy, useContext, useEffect, useState, useRef, forwardRef } from 'react'
import QuestionFooter from '../question-footer'
import { isBetSelectable } from 'common/lib/bets'
import { formatDate, formatDistance } from 'common/util/dateFormater'
import questionService from 'api/question'
import ClockIcon from 'common/components/icons/clock'
import parseISO from 'date-fns/parseISO'
import MarketContext from 'app/context/MarketContext'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { selectMarketById } from 'store/markets'
import { i18n } from 'common/locale/i18n'
import { useActiveCurrency, useCurrencies } from 'common/hooks'
import { keepQueryString, questionDetailUrl } from 'urls'
import { Link, useNavigate } from 'react-router-dom'
import { compareDesc } from 'date-fns'
import { getWagerSelected } from 'store/wagers'
import 'portfolio/components/bet/Bet.scss'
import useIsMobileSize from 'common/hooks/useIsMobileSize'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import CategoryList from '../CategoryList'
const OutcomeList = lazy(() => import('questions/components/outcome-list'))
const QuestionHeader = lazy(() => import('questions/components/question-header'))
const UnavailableMarket = lazy(() => import('app/components/unavailable-market'))
const Bet = lazy(() => import('portfolio/components/bet'))

// Skeleton loaders for lazy-loaded components
// Skeleton loader for Bet component
const BetSkeleton = () => {
  return (
    <div className="animate-pulse px-3 py-2 rounded-lg  ">
      <div className="flex justify-between items-center mb-2">
        <div className="h-5 bg-[var(--color-Neutral-2)] rounded w-1/4"></div>
        <div className="h-5 bg-[var(--color-Neutral-2)] rounded w-1/6"></div>
      </div>
      <div className="flex justify-between items-center">
        <div className="h-6 bg-[var(--color-Neutral-2)] rounded w-1/3"></div>
        <div className="h-6 bg-[var(--color-Neutral-2)] rounded w-1/4"></div>
      </div>
    </div>
  )
}

// Skeleton loader for QuestionHeader component
const QuestionHeaderSkeleton = () => {
  return (
    <div className="animate-pulse px-3 py-2 rounded-lg  ">
      <div className="flex justify-between items-center mb-3">
        <div className="h-6 bg-[var(--color-Neutral-2)] rounded w-3/4"></div>
        <div className="h-6 bg-[var(--color-Neutral-2)] rounded-full w-6"></div>
      </div>
      <div className="flex items-center">
        <div className="h-4 bg-[var(--color-Neutral-2)] rounded w-1/4 mr-2"></div>
        <div className="h-4 bg-[var(--color-Neutral-2)] rounded w-1/5"></div>
      </div>
    </div>
  )
}

// Skeleton loader for OutcomeList component
const OutcomeListSkeleton = () => {
  return (
    <div className="animate-pulse p-3 w-full">
      {[1, 2].map(i => (
        <div
          key={i}
          className="flex justify-between items-center mb-3 px-3 py-1 border border-[var(--color-Neutral-2)] rounded-lg">
          <div className="flex items-center">
            <div className="h-10 w-10 bg-[var(--color-Neutral-2)] rounded-full mr-3"></div>
            <div className="h-5 bg-[var(--color-Neutral-2)] rounded w-24"></div>
          </div>
          <div className="h-6 bg-[var(--color-Neutral-2)] rounded w-16"></div>
        </div>
      ))}
    </div>
  )
}

// Skeleton loader for UnavailableMarket component
const UnavailableMarketSkeleton = () => {
  return (
    <div className="animate-pulse p-4 rounded-lg bg-[var(--color-surface-1)]">
      <div className="flex justify-center items-center">
        <div className="h-6 bg-[var(--color-Neutral-2)] rounded w-3/4 mx-auto"></div>
      </div>
    </div>
  )
}

// Preload components function
const preloadComponents = () => {
  // Preload all lazy-loaded components
  import('portfolio/components/bet')
}

// Store the clicked market card's position and dimensions for transition animation
const transitionStateData = {
  element: null,
  rect: null,
  scrollY: 0,
}

// Export a getter and setter for the transition state
export const getTransitionState = () => transitionStateData
export const setTransitionState = newState => {
  transitionStateData.element = newState.element
  transitionStateData.rect = newState.rect
  transitionStateData.scrollY = newState.scrollY
}

const MarketCard = forwardRef(
  (
    {
      categories,
      showSelectedOutcome,
      isRelatedMarket = false,
      currentOutcomeSelection,
      onOutcomeSelected = () => {},
      showSummary,
      inlineBehavior,
      className = '',
      inMarketPage,
      noAnimation = false,
      setMarketSelected,
      id,
      isModalComponent = false,
      isSelectedToBet = false,
      showBetStatus = false,
      forceBetListCollapsed = false,
      onToggleBetList = () => {},
      ...props
    },
    externalRef
  ) => {
    const currencies = useCurrencies().currencies
    const { marketEnable } = useContext(MarketContext)
    const questionId = (props.question && props.question.id) || props.questionId
    const question = props.question || useSelector(selectMarketById(questionId))
    const [selectedOutcome, setSelectedOutcome] = useState(currentOutcomeSelection)
    const activeCurrency = useActiveCurrency()
    const isRealMoney = activeCurrency && activeCurrency?.id !== currencies.OOM.id
    const availableMarket = (question && !isRealMoney) || (isRealMoney && question.real_currency_available)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isMobileSize = useIsMobileSize()
    const [isBetListCollapsed, setIsBetListCollapsed] = useState(true)

    const betList = props.betList || question.wagers || []
    useEffect(() => {
      setSelectedOutcome(currentOutcomeSelection)
    }, [currentOutcomeSelection])

    // Apply forced collapse state from parent component
    useEffect(() => {
      if (forceBetListCollapsed) {
        setIsBetListCollapsed(true)
      }
    }, [forceBetListCollapsed])

    const toggleBetListCollapse = () => {
      const newCollapsedState = !isBetListCollapsed
      setIsBetListCollapsed(newCollapsedState)
      // Notify parent component about the toggle
      onToggleBetList(newCollapsedState)
    }

    // Preload Bet component on hover
    const handleCollapseButtonHover = () => {
      if (isBetListCollapsed && showBetStatus) {
        // Preload the Bet component
        console.log();
        
        import('portfolio/components/bet')
      }
    }

    // Preload components when the component mounts
    useEffect(() => {
      if (question && question.wagers) {
        preloadComponents()
      }
    }, [question])

    const goToQuestionDetails = () => {
      // Capture the current position and dimensions of the card for animation
      if (cardRef.current && !inMarketPage) {
        const rect = cardRef.current.getBoundingClientRect()
        setTransitionState({
          element: cardRef.current,
          rect: {
            top: rect.top,
            left: rect.left,
            width: rect.width,
            height: rect.height,
          },
          scrollY: window.scrollY,
        })
      }

      navigate(questionDetailUrl(question), {
        state: {
          outcome: selectedOutcome,
          transition: true,
        },
      })
    }

    useEffect(() => {
      if (!inlineBehavior && selectedOutcome) goToQuestionDetails()
    }, [selectedOutcome])

    /* Dynamically measure card width*/
    const cardRef = useRef(null)
    // Combine external ref with internal ref
    const combinedRef = ref => {
      cardRef.current = ref
      if (externalRef) {
        if (typeof externalRef === 'function') {
          externalRef(ref)
        } else {
          externalRef.current = ref
        }
      }
    }

    const [cardWidth, setCardWidth] = useState(0)

    useEffect(() => {
      const updateCardWidth = () => {
        if (cardRef.current) {
          setCardWidth(cardRef.current.offsetWidth)
        }
      }

      // Initial width check and add listener for resizing
      updateCardWidth()
      window.addEventListener('resize', updateCardWidth)

      return () => window.removeEventListener('resize', updateCardWidth)
    }, [])

    const activeFirst = (acc, element) => {
      if (element.status === 'p') {
        return [element, ...acc]
      }
      return [...acc, element]
    }
    const renderBetList = betList => {
      const openBetList = betList.filter(bet => bet.status !== 's')
      const soldBetList = betList.filter(bet => bet.status === 's')

      // Sort the open bets by last update date
      const sortedOpenBets = openBetList
        .sort((bet1, bet2) => compareDesc(new Date(bet1.last_update), new Date(bet2.last_update)))
        .reduce(activeFirst, [])

      // Sort the sold bets by last update date
      const sortedSoldBets = soldBetList
        .sort((bet1, bet2) => compareDesc(new Date(bet1.last_update), new Date(bet2.last_update)))
        .reduce(activeFirst, [])

      // Total number of bets
      const totalBets = sortedOpenBets.length + sortedSoldBets.length

      // Determine which bet to show when not in market page
      const betToShow =
        sortedOpenBets.length > 0 ? sortedOpenBets[0] : sortedSoldBets.length > 0 ? sortedSoldBets[0] : null

      return (
        <>
          <div
            className={classNames('question-card__section--wide question-card__bet-status', {
              'rounded-b-lg': !inMarketPage,
            })}>
            {inMarketPage && (
              <div className="mb-2">
                <h4 className="question-card__title bet_status_title">{i18n('question_card.bets_on_the_market')}</h4>
              </div>
            )}

            {/* When in market page, show all open bets */}
            {inMarketPage &&
              sortedOpenBets.map(wager => (
                <div key={`market--bet-${wager.id}`} className="question-card__bet-entry">
                  <Suspense fallback={<BetSkeleton />}>
                    <Bet
                      market={question}
                      bet={wager}
                      onSell={() => {
                        dispatch(getWagerSelected({ ...wager, _diff: Math.random() }))
                        if (setMarketSelected) setMarketSelected(question)
                      }}
                      onBetInfoClick={() => navigate(questionDetailUrl(question))}
                    />
                  </Suspense>
                </div>
              ))}

            {/* When not in market page, show one bet with priority to open bets */}
            {!inMarketPage && betToShow && (
              <div key={`market--bet-${betToShow.id}`} className="question-card__bet-entry">
                <Suspense fallback={<BetSkeleton />}>
                  <Bet
                    market={question}
                    bet={betToShow}
                    onSell={() => {
                      if (betToShow.status !== 's') {
                        dispatch(getWagerSelected({ ...betToShow, _diff: Math.random() }))
                        if (setMarketSelected) setMarketSelected(question)
                      }
                    }}
                    onBetInfoClick={() => navigate(questionDetailUrl(question))}
                  />
                </Suspense>
              </div>
            )}

            {/* When in market page, show all sold bets */}
            {inMarketPage &&
              sortedSoldBets.map(wager => (
                <div key={`market--bet-${wager.id}`} className="question-card__bet-entry">
                  <Suspense fallback={<BetSkeleton />}>
                    <Bet market={question} bet={wager} onBetInfoClick={() => navigate(questionDetailUrl(question))} />
                  </Suspense>
                </div>
              ))}

            {/* When not in market page and there are more than 1 bet in total, show a "View all" button */}
            {!inMarketPage && totalBets > 1 && (
              <div>
                <Link
                  to={keepQueryString(questionDetailUrl(question))}
                  className="w-full font-bold flex justify-center link"
                  style={{ padding: '8px', width: '100%' }}>
                  <span>
                    {i18n('question_card.view_all')} ({totalBets})
                  </span>
                </Link>
              </div>
            )}
          </div>
        </>
      )
    }

    const renderQuestionHeader = () => {
      let questionSummary

      if (questionService.isWaitingForResults(question)) {
        questionSummary = renderWaitingForResultsMessage()
      }

      if (questionService.isCancelled(question)) {
        questionSummary = renderCancelledInfoMessage(question)
      }

      if (questionService.isResolved(question)) {
        questionSummary = renderResolvedQuestionLabel(question)
      }

      if (!questionSummary && questionService.hasUpcomingEvent(question)) {
        questionSummary = renderEventStartDateLabel(question)
      }

      return (
        <Suspense fallback={<QuestionHeaderSkeleton />}>
          <QuestionHeader
            showBrierScore={showSummary && question.complete}
            categories={categories}
            marketEnabled={marketEnable}
            question={question}
            inMarketPage={inMarketPage}
            marketCardWidth={cardWidth}
            isRealMoney={isRealMoney}
          />
          {/* {questionSummary} */}
        </Suspense>
      )
    }

    const getBetForActiveCurrency = betList => betList.find(bet => bet.currency === activeCurrency?.id)

    const renderWaitingForResultsMessage = () => {
      return <div className="question-card__results-label">{i18n('question_card.waiting_result')}</div>
    }

    const renderCancelledInfoMessage = () => {
      return <div className="question-card__cancelled-label">{i18n('question_card.date.cancelled')}</div>
    }

    const renderResolvedQuestionLabel = question => {
      return (
        <div className="question-card__resolved-label">
          {formatDate(parseISO(question.resolve_date))}
          <br />
          <strong>{i18n('question_card.date.resolved')}</strong>
        </div>
      )
    }

    const renderEventStartDateLabel = question => {
      const startDateTS = Date.parse(question.event_start_date)
      return (
        <div className="question-card__start-date-label">
          <ClockIcon fill="#1591ed" width="15px" /> {i18n('question_card.live_in')}{' '}
          {formatDistance(startDateTS, Date.now())}
        </div>
      )
    }

    const renderUnavailableMessage = () => {
      return (
        <div className="question-card__unavailable-message">
          <Suspense fallback={<UnavailableMarketSkeleton />}>
            <UnavailableMarket />
          </Suspense>
        </div>
      )
    }

    const renderOutcomeList = betList => {
      if (!question) return

      // Skip rendering outcome list for yes/no questions when in market page
      if (inMarketPage && !isMobileSize && question.outcomes_type === 'yesno') {
        return null
      }

      const bet = getBetForActiveCurrency(betList)
      const outcomeClickable = isBetSelectable(bet) && availableMarket

      // Create a handler that can handle both regular clicks and yes/no clicks
      const handleOutcomeClick = (outcome, position) => {
        if (outcomeClickable) {
          onOutcomeSelected(outcome, position)
        }
      }

      return (
        <Suspense fallback={<OutcomeListSkeleton />}>
          <OutcomeList
            outcomes={question.outcomes}
            question={question}
            currencyId={activeCurrency?.id}
            selectedId={outcomeClickable && showSelectedOutcome && selectedOutcome && selectedOutcome.id}
            bet={bet}
            wager={bet && bet.outcome}
            onItemClick={handleOutcomeClick}
            isRelatedMarket={isRelatedMarket}
            noAnimation={noAnimation}
            inMarketPage={inMarketPage}
          />
        </Suspense>
      )
    }

    const questionInfoClassList = classNames('question-card__section', 'question-card__main', className, {
      'rounded-t-lg': !inMarketPage,
      'rounded-b-lg': !inMarketPage && isBetListCollapsed,
      'overflow-hidden h-[300px]': !inMarketPage,
      'mt-6': inMarketPage,
    })
    const hasBets = betList.length && betList.length > 0
    const shouldShowBetStatus = showBetStatus && hasBets

    // Preload the Bet component when the component mounts
    useEffect(() => {
      if (showBetStatus) {
        // Preload the Bet component
        import('portfolio/components/bet')
      }
    }, [showBetStatus])

    if (question == null) {
      return null
    }

    // Create article class list
    const articleClassList = classNames('question-card', {
      'question-card--show-bet-status': shouldShowBetStatus,
      'question-card--is-modal': isModalComponent,
      'question-card--is-selected': isSelectedToBet,
    })

    const [animationParent] = useAutoAnimate()

    return (
      <article className={`${articleClassList} flex flex-col`}>
        {inMarketPage && categories && categories.length > 0 && <CategoryList categories={categories} />}

        <div className={`${questionInfoClassList} flex flex-col`} id={id} ref={combinedRef}>
          <div className="flex-shrink-0">{renderQuestionHeader()}</div>
          <div className="flex-grow overflow-auto min-h-0 flex">{renderOutcomeList(betList)}</div>
          <div className="flex-shrink-0">
            <QuestionFooter
              question={question}
              inMarketPage={inMarketPage}
              hasBets={hasBets}
              isBetListCollapsed={isBetListCollapsed}
              toggleBetListCollapse={toggleBetListCollapse}
              onCollapseButtonHover={handleCollapseButtonHover}
            />
            {!availableMarket && renderUnavailableMessage()}
          </div>
        </div>

        <div ref={animationParent}>
          {Boolean(shouldShowBetStatus) && (!isBetListCollapsed || inMarketPage) && renderBetList(betList)}
        </div>
      </article>
    )
  }
)
export default MarketCard
