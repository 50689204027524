import requestService from 'common/services/request'
import {createSlice} from '@reduxjs/toolkit'
import constants from 'app/constants'
import {getCurrencyMode} from 'common/util/currencies'
import {addMarketsToPool} from 'store/markets'
import {addTagsToPool} from 'store/tags'
import {addCategoriesToPool} from 'store/categories'
import {toastService} from "api/toast";

const initialState = {
  categories: { pagination: {}, data: [], status: constants.IDLE },
  forecasters: { pagination: {}, data: [], status: constants.IDLE },
  markets: { pagination: {}, data: [], status: constants.IDLE },
  tags: { pagination: {}, data: [], status: constants.IDLE },
}

const searchReset = (searchType, state) => {
  state[searchType].pagination = {}
  state[searchType].error = null
  state[searchType].data = []
  state[searchType].status = constants.IDLE
}

const searchStart = (searchType, state) => {
  state[searchType].status = constants.LOADING
}

const searchError = (searchType, state, error) => {
  if (!state[searchType]) return
  state[searchType].error = error
  state[searchType].status = constants.ERROR
}

const createQueryString = (query, params) => {
  const {
    ordering,
    live_only,
    categories,
    resolved_only,
    limit,
    offset,
    category,
    people_i_follow,
    currencyId = 'OOM',
  } = params
  let qs = `search=${query}`

  if (ordering != null && ordering.length > 0) {
    qs += `&ordering=${ordering}`
  }

  if (live_only != null && live_only.length > 0) {
    qs += `&live_only=${live_only}`
  }

  if (resolved_only != null && resolved_only.length > 0) {
    qs += `&resolved_only=${resolved_only}`
  }

  if (categories != null && categories.length > 0) {
    qs += `&categories=${categories}`
  }
  if (category != null && category.length > 0) {
    qs += `&category=${category}`
  }
  if (people_i_follow != null && people_i_follow.length > 0) {
    qs += `&people_i_follow=${people_i_follow}`
  }

  if (limit) {
    qs += `&limit=${limit}`
  }

  if (offset) {
    qs += `&offset=${offset}`
  }

  if (currencyId) {
    qs += '&currency_mode=' + getCurrencyMode(currencyId)
  }

  return qs
}

// Reducers / Slices

const search = createSlice({
  name: 'search',
  initialState,
  reducers: {
    // ? Markets
    searchMarketsReset: state => {
      searchReset('markets', state)
    },

    searchMarketsStart: state => {
      searchStart('markets', state)
    },

    searchMarketsSuccess: (state, action) => {
      const { results, pagination, total } = action.payload

      let markets = [...state.markets.data]

      let current_markets_ids = state.markets.data.map((market) => market.id)

      results.forEach((fetchedMarket) => {
        if(!current_markets_ids.includes(fetchedMarket.id))
        {
          markets.push(fetchedMarket)
        }
      })

      state.markets.data = markets
      state.markets.pagination = pagination ? pagination : { total }
      state.markets.status = constants.COMPLETE
    },

    searchMarketsError: (state, action) => {
      const { error } = action.payload
      searchError('markets', state, error)
    },

    startMarketsLoading: (state) => {
        state.markets.status = constants.LOADING
    },

    searchForecasterReset: state => {
      searchReset('forecasters', state)
    },

    searchForecastersStart: state => {
      searchStart('forecasters', state)
    },

    searchForecastersSuccess: (state, action) => {
      const { results, pagination, total } = action.payload

      let forecasters = [...state.forecasters.data]
      let current_forecasters_ids = state.forecasters.data.map((forecaster) => forecaster.id)

      results.forEach((fetchedForecaster) => {
        if(!current_forecasters_ids.includes(fetchedForecaster.id))
        {
          forecasters.push(fetchedForecaster)
        }
      })

      state.forecasters.data = forecasters
      state.forecasters.pagination = pagination ? pagination : { total }
      state.forecasters.status = constants.COMPLETE
    },

    searchForecastersError: (state, action) => {
      const { error } = action.payload
      searchError('forecasters', error)
    },

    startForecastersLoading: (state) => {
      state.forecasters.status = constants.LOADING
    },

    // ? Categories
    searchCategoriesReset: state => {
      searchReset('categories', state)
    },

    searchCategoriesStart: state => {
      searchStart('categories', state)
    },

    searchCategoriesSuccess: (state, action) => {
      const { results, pagination, total } = action.payload

      let categories = [...state.categories.data]
      let current_categories_ids = state.categories.data.map((category) => category.id)

      results.forEach((fetchedCategory) => {
        if(!current_categories_ids.includes(fetchedCategory.id))
        {
          categories.push(fetchedCategory)
        }
      })

      state.categories.data = categories
      state.categories.pagination = pagination ? pagination : { total }
      state.categories.status = constants.COMPLETE
    },

    searchCategoriesError: (state, action) => {
      searchError('categories', state, action.payload.error)
    },

    // ? Tags
    searchTagsReset: state => {
      searchReset('tags', state)
    },

    searchTagsStart: state => {
      searchStart('tags', state)
    },

    searchTagsSuccess: (state, action) => {

      const { results, pagination, total } = action.payload

      let tags = [...state.tags.data]
      let current_tags_ids = state.tags.data.map((tag) => tag.id)

      results.forEach((fetchedTag) => {
        if(!current_tags_ids.includes(fetchedTag.id))
        {
          tags.push(fetchedTag)
        }
      })

      state.tags.data = tags
      state.tags.pagination = pagination ? pagination : { total }
      state.tags.status = constants.COMPLETE
    },

    searchTagsError: (state, action) => {
      searchError('tags', state, action.payload.error)
    },
  },
})

export const {
  searchMarketsReset,
  searchMarketsStart,
  searchMarketsSuccess,
  searchMarketsError,
  startMarketsLoading,
  searchForecasterReset,
  searchForecastersStart,
  searchForecastersSuccess,
  searchForecastersError,
  startForecastersLoading,
  searchCategoriesStart,
  searchCategoriesSuccess,
  searchCategoriesError,
  searchCategoriesReset,
  searchTagsStart,
  searchTagsSuccess,
  searchTagsError,
  searchTagsReset,
} = search.actions

export default search.reducer

// Selectors:

export const selectMarkets = () => state => state.search.markets.data
export const selectForecasters = () => state => state.search.forecasters.data
export const selectCategories = () => state =>state.search.categories.data

export const selectTags = () => state => state.search.tags.data

export const selectMarketsStatus = () => state => state.search.markets.status
export const selectCategoriesStatus = () => state => state.search.categories.status
export const selectForecastersStatus = () => state => state.search.forecasters.status
export const selectTagsStatus = () => state => state.search.tags.status

export const selectMarketsPagination = () => state => state.search.markets.pagination || {}
export const selectForecastersPagination = () => state => state.search.forecasters.pagination || {}
export const selectCategoriesPagination = () => state => state.search.categories.pagination || {}
export const selectTagsPagination = () => state => state.search.tags.pagination || {}

export const resetSearch = () => dispatch => {
  dispatch(searchMarketsReset())
  dispatch(searchForecasterReset())
  dispatch(searchCategoriesReset())
  dispatch(searchTagsReset())
}

export const searchMarkets = (query = '', params = {}) => async dispatch => {

  const qs = createQueryString(query, params)
  const url = `/questions/?${qs}`

  dispatch(searchMarketsStart())

  try {
    const response = await requestService.get(url)
    const data = await response.json()

    await dispatch(searchMarketsSuccess(data))
  }
  catch (err) {
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    dispatch(searchMarketsError(err))
  }
}

export const searchForecasters = (query = '', params = {}) => async dispatch => {
  const qs = createQueryString(query, params)
  const url = `/users/?${qs}`

  dispatch(searchForecastersStart())

  try {
    const response = await requestService.get(url)
    const data = await response.json()

    dispatch(searchForecastersSuccess(data))
  }
  catch (err) {
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    dispatch(searchForecastersError(err))
  }
}

export const searchCategories = (query = '', params = {}) => async dispatch => {
    const currencyMode = getCurrencyMode(params.currencyId)
    const url = `/categories/?search=${query}&currency_mode=${currencyMode}`

    dispatch(searchCategoriesStart())

    try {
      const response = await requestService.get(url)
      const data = await response.json()

      dispatch(searchCategoriesSuccess(data))
    }
    catch (err) {
      toastService('fail', {
        title: err.errors && err.errors[0].detail,
        body: err.errors && err.errors[0].code,
      })
      dispatch(searchCategoriesError(err))
    }
  }

export const searchTags = (query = '', params = {}) => async dispatch => {
  const currencyMode = getCurrencyMode(params.currencyId)
  const url = `/tags/?search=${query}&currency_mode=${currencyMode}`

  dispatch(searchTagsStart())

  try {
    const response = await requestService.get(url)
    const data = await response.json()

    dispatch(searchTagsSuccess(data))
  }
  catch (err) {
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    dispatch(searchTagsError(err))
  }
}

export const searchAll = (query = {}, params = {}) => async dispatch => {
    const searchParams = []
    query.search = query.q || ''
    delete query.q
    for (const property in query) {
      searchParams.push(property + '=' + query[property])
    }
    const currencyMode = getCurrencyMode(params.currencyId)
    const url = `/search/?${searchParams.join('&')}&currency_mode=${currencyMode}`

    dispatch(searchMarketsStart())
    dispatch(searchCategoriesStart())
    dispatch(searchTagsStart())

    try {
      var response = await requestService.get(url)
    } catch (error) {
      dispatch(searchMarketsError({ error }))
      dispatch(searchCategoriesError({ error }))
      dispatch(searchTagsError({ error }))
    }

    const json = await response.json()
    dispatch(addMarketsToPool({ markets: json.questions }))
    dispatch(searchMarketsSuccess({ results: json.questions, total: json.questions_total }))

    dispatch(addCategoriesToPool({ categories: json.categories }))
    dispatch(searchCategoriesSuccess({ results: json.categories, total: json.categories_total }))

    dispatch(addTagsToPool({ tags: json.tags }))
    dispatch(searchTagsSuccess({ results: json.tags, total: json.tags_total }))
  }
