import { i18n } from 'common/locale/i18n'
import React from 'react'
import './MarketListHeader.scss'

const MarketListHeader = ({ numberOfActiveBets = 0, isLoading, filters, title = '' }) => {
  return (
    <>
      <div className="flex flex-row justify-content-between items-center pb-6 !px-4 md:!px-[30px]">
        <div className="flex flex-row gap-4 items-center">
          {title && <h3 className="text-2xl text-Neutral-6 leading-[133%] font-semibold">{title}</h3>}

          {!isLoading && (
            <div className="text-sm text-Neutral-4  ">
              {numberOfActiveBets} {i18n('active_markets.results')}
            </div>
          )}
        </div>

        <div className="flex-gap-4">{filters}</div>
      </div>
    </>
  )
}

export default MarketListHeader
