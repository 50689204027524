import React from 'react'
import PropTypes from 'prop-types'
import GoogleButton from 'session/components/google-button/GoogleButton'
import FacebookLogin from 'session/facebook/FacebookLogin'
import EmailLogin from 'session/components/EmailLogin'
import { i18n } from 'common/locale/i18n'

const AuthPanel = ({ hideEmail = false, onLogin }) => {
  return (
    <div className="flex gap-4 flex-col md:flex-row ">
      <div className="w-full">
        <GoogleButton onLogin={onLogin} />
      </div>
      <div className="w-full">
        <FacebookLogin onSuccess={onLogin} label={i18n('login.facebook_sign_in')} />
      </div>

      {!hideEmail && (
        <div className="w-full">
          <EmailLogin label={i18n('public_make_bet.email_enter')} />
        </div>
      )}
    </div>
  )
}

AuthPanel.propTypes = {
  introText: PropTypes.string,
  footerText: PropTypes.string,
}

export default AuthPanel
