import { combineReducers, configureStore } from '@reduxjs/toolkit'
import app from './app'
import account from './account'
import banners from './banners'
import categories from './categories'
import markets from './markets'
import wagers from './wagers'
import wallet from './wallet'
import users from './users'
import search from './search'
import notifications from './notifications'
import tags from './tags'
import comments from './comments'
import feedback from './feedback'
import settingsService from 'common/services/settings'

const store = configureStore({
  devTools: true,
  reducer: combineReducers({
    app,
    account,
    banners,
    categories,
    markets,
    wagers,
    wallet,
    users,
    search,
    tags,
    notifications,
    comments,
    feedback,
  }),
})

// Set the store in the settings service to break circular dependency
settingsService.setStore(store)

export default store
