/* @flow */

export default function queryInjector(pathname, query) {
  if (!query || query.length === 0) {
    return pathname
  }

  let queryStr = ''
  for (const key of Object.keys(query)) {
    const value = query[key]
    const keyEncoded = encodeURIComponent(key)

    switch (typeof value) {
      case 'string':
        queryStr += `&${keyEncoded}=${encodeURIComponent(value)}`
        break
      case 'number':
        queryStr += `&${keyEncoded}=${value}`
        break
      case 'object': // object, array or null
        if (value !== null) {
          try {
            const jsonValue = JSON.stringify(value)
            queryStr += `&${keyEncoded}=${encodeURIComponent(jsonValue)}`
          } catch (ignored) {
            console.warn(`Can't coerce key ${key} ${ignored} value to json`)
            break
          }
        }
        break
      case 'boolean':
        queryStr += `&${keyEncoded}=${value ? 'true' : 'false'}`
        break
      case 'function':
        console.warn(`Can't coerce key ${key} value of type "function" to query parameter`)
        break
      case 'undefined':
      default:
        break
    }
  }

  if (queryStr.length === 0) {
    return pathname
  }

  return `${pathname}?${queryStr.substr(1)}`
}
