import React from 'react'
import './stats.scss'
export default function StatsIcon({ className, fillcolor="currentColor",...props }) {
  return (
    <svg viewBox="0 0 16 17" className={className} fill={fillcolor} {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 9H3.83333V16.5H0.5V9ZM12.1667 5.66667H15.5V16.5H12.1667V5.66667ZM6.33333 0.666668H9.66667V16.5H6.33333V0.666668Z"
        fill={fillcolor}
      />
    </svg>
  )
}
