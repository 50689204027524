import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchInfo, selectInfo } from 'store/app'
import { AppUpdate } from '@robingenz/capacitor-app-update'

const useGeneralInfo = () => {
  const dispatch = useDispatch()
  const info = useSelector(selectInfo)

  useEffect(() => {
    dispatch(fetchInfo())
  }, [])
  const getCurrentAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo()
    return result.currentVersion
  }
  const refreshCache = async () => {
    if (info && info.info) {
      const newDeploy = localStorage.getItem('WEBAPP_LAST_COMMIT') != info?.info?.WEBAPP_LAST_COMMIT
      if (!newDeploy) return
      localStorage.setItem('WEBAPP_LAST_COMMIT', info.info?.WEBAPP_LAST_COMMIT) 
    }
  }

  return { ...info, getCurrentAppVersion, refreshCache }
}

export default useGeneralInfo
