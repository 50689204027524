import React, { useState } from 'react'

const MarketContext = React.createContext({
  activeCurrency: 'OOM',
  setActiveCurrency: () => {},
  marketEnable: false,
  setMarketEnable: () => {},
})

export const MarketProvider = ({ children }) => {
  const [activeCurrency, setActiveCurrency] = useState('OOM');
  const [marketEnable, setMarketEnable] = useState(false);

  return (
    <MarketContext.Provider 
      value={{ 
        activeCurrency, 
        setActiveCurrency, 
        marketEnable, 
        setMarketEnable 
      }}
    >
      {children}
    </MarketContext.Provider>
  );
};

export default MarketContext
