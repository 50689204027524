/* @flow */
import './validatorNotIn'

const minLength = 3
const maxLength = 50
const restrictedNames = [
  'account',
  'accounts',
  'admin',
  'admins',
  'about',
  'contact',
  'contacts',
  'category',
  'categories',
  'community',
  'communities',
  'feed',
  'futuur',
  'home',
  'login',
  'leaderboard',
  'notification',
  'notifications',
  'onboarding',
  'prediction',
  'q',
  's',
  'p',
  'user',
  'users',
]

export const pattern = '^[a-zA-Z0-9_]+$'

export const rules = {
  minLength,
  maxLength,
  notIn: restrictedNames,
  matchRegexp: new RegExp(pattern),
}

export const errors = {
  minLength: `Must be longer than ${minLength} characters`,
  maxLength: `Must be shorter than ${maxLength} characters`,
  notIn: 'This nickname is restricted',
  matchRegexp: 'Use only letters, numbers or underscore',
}
